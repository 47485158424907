import Icon from '@material-ui/core/Icon'
import React, { useCallback } from 'react'
import './N2Clipboard.css'
import { showSnackbar } from './N2Snackbar'


const N2Clipboard = ({ text, label, look = 'simple', icon, children }) => {
	const handleClick = useCallback(e => {
		navigator.clipboard.writeText(text).then(() => {
			showSnackbar('copied')
		}, error => {
			showSnackbar(error)
		})
	}, [text])

	let c = 'N2Clipboard ' + (look !== '' ? `N2Clipboard--${look}` : 'N2Clipboard--dottedBorder')
	let i = (icon != null) ? icon : <Icon fontSize="small" color="primary">file_copy</Icon>

	return text!=null ? <div className={c} onClick={handleClick}>
		<div>{label || children || text}</div>
		<div>{i}</div>
	</div> : null
}

export default N2Clipboard