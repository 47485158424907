import { BottomNavigation, BottomNavigationAction, Hidden, IconButton, Tab, Tabs } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import classnames from 'classnames';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { useQuery } from 'react-query';
import { APP_API_URL, APP_RANKS } from '../app/const';
import { useUser } from '../n2shared/N2App';
import { useQueryEntity } from '../n2shared/N2EventFeed';
import { N2I18N2, useN2I18N2 } from '../n2shared/N2I18N2';
import N2Skeleton from '../n2shared/N2Skeleton';
import N2Thumbnailer from '../n2shared/N2Thumbnailer';
import { N2Tuple } from '../n2shared/N2Tuple';
import { N2Content, N2Toolbar, N2View } from '../n2shared/N2View';
import { COUNTRIES } from '../n2shared/countries';
import { useRequireRole } from '../n2shared/n2appHooks';
import { array2date, hash } from '../n2shared/n2functions';
import RankAvatar from './RankAvatar';
import './Team.scss';
import TeamGraph from './TeamGraph';
import { TrainingProgress } from './Training';


export const TeamContext = React.createContext()


export function Team() {
	useRequireRole('USER')

	const { data: user } = useUser()
	const [tab, setTab] = useState(0)
	const [userId, setUserId] = useState()

	return user ? <N2View>
		<N2Toolbar left={<h3><N2I18N2 id="client.team@title" def="Team" /> <NetworkId user={user} /></h3>} />
		<N2Content className="Team">
			<TeamContext.Provider value={{ userId, setUserId }}>
				<div className="topTabs">
					<Tabs value={tab} onChange={(e,t) => setTab(t)} indicatorColor="primary" textColor="primary">
						<Tab label={<N2I18N2 id="client.team@tree" def="Tree"/>} icon={<Icon>account_tree</Icon>} />
						<Tab label={<N2I18N2 id="client.team@graph" def="Graph"/>} icon={<Icon>bubble_chart</Icon>} />
					</Tabs>
				</div>
				<div className="content">
					<div className={`inspectorBox tab${tab}`}>
						<Inspector userId={userId} close={e => setUserId(null)} />
					</div>
					{tab===0  && <Tree />}
					{tab===1  && <TeamGraph />}
				</div>
				<div className="bottomNav">
					<BottomNavigation value={tab} onChange={(e,t) => setTab(t)} showLabels>
						<BottomNavigationAction label={<N2I18N2 id="client.team@tree" def="Tree"/>} icon={<Icon>account_tree</Icon>} />
						<BottomNavigationAction label={<N2I18N2 id="client.team@graph" def="Graph"/>} icon={<Icon>bubble_chart</Icon>} />
						{/* <BottomNavigationAction label={<N2I18N2 id="client.team@customers" def="Customers"/>} icon={<Icon>face</Icon>} /> */}
					</BottomNavigation>
				</div>
			</TeamContext.Provider>
		</N2Content>
	</N2View> : null
}


function Tree() {
	const { setUserId } = useContext(TeamContext)
	const { data: user } = useUser()
	const { data: upline, isLoading: uplineLoading } = useQuery('upline', async () => {
		return (await axios.get(`${APP_API_URL}/rest/user/upline`)).data.reverse()
	}, { enabled: user != null, staleTime: 5_000 })

	return <div className="Tree">
		{uplineLoading
			? <N2Skeleton type="list" pattern="1% 2% 1% 30% grow" lines={3} />
			: upline?.map(u => <div key={u.id} className={classnames('uplinenode', 'UserInfo', {disabled: !u.enabled})}>
				<NetworkId user={u} />
				<CountryFlag countryIso={u.countryIso} />
				{u.photo && <div className="photo"><UserPhoto user={u} size={24} /></div>}
				<div className="label">{u.name}</div>
			</div>)
		}
		<div className="sublevel">
			<Firstline user={user} setUserId={setUserId} />
		</div>
	</div>
}


function Firstline({ user, firstlineCount, setUserId }) {
	const { data: firstline, isLoading } = useQuery(['firstline', user.id], async () => {
		return (await axios.get(`${APP_API_URL}/rest/user/firstline?${new URLSearchParams({ ofId: user.id })}`)).data
	}, { staleTime: 30_000 })
	const [open, setOpen] = useState({})

	const toggle = useCallback(id => {
		setOpen(prev => ({
			...prev,
			[id]: !prev[id]
		}))
		setUserId(id)
	}, [setUserId])

	return isLoading
		? <N2Skeleton type="list" lines={firstlineCount} pattern="10% 20% 10% grow 15%" />
		: firstline ? firstline.map(f => <div key={f.id}>
			<div onClick={e => toggle(f.id)} className="subnode">
				<UserInfo user={f} labelFun={u => u.name} />
				<Hidden xsDown><YieldInfo y={f.realtimeYield} /></Hidden>
				<Hidden xsDown><TrainingProgress size={20} pct={f.trainingPct || 0} withLabel={false} /></Hidden>
				{(f.firstlineCount>0 && !open[f.id]) && <span className="firstlineCount">
					<Icon fontSize="small">arrow_drop_down</Icon><FormattedNumber value={f.firstlineCount} />
				</span>}
			</div>
			<div className="sublevel">
				{open[f.id] && <Firstline user={f} firstlineCount={f.firstlineCount} setUserId={setUserId} />}
			</div>
		</div>) : null
}


function Inspector({ userId, close }) {
	const [{ data: user, isLoading, error }] = useQueryEntity(
		['downline', userId],
		`${APP_API_URL}/rest/user/downline/${userId}`,
		{ enabled: userId != null, staleTime: 30_000 })

	return userId ? <div className="Inspector">
		{isLoading
			? <N2Skeleton type="list" pattern="grow 20%" lines={3} />
			: <div>
				{error && <Alert severity="error">{error}</Alert>}
				{user && <div>
					<IconButton onClick={close} size="small" style={{ float: 'right' }}><Icon>close</Icon></IconButton>
					<UserPhoto user={user} size={100}  />
					<header>
						<NetworkId user={user} />
						<CountryFlag countryIso={user.countryIso} />
						<span className="name">{user.name}</span>
						{/* {user.kycStatus === 'VERIFIED'
							? <Icon fontSize="small" className="n2success">check_circle</Icon>
							: <Icon fontSize="small" className="n2warning">warning</Icon>} */}
					</header>
					<Rank user={user} />
					{(user.street || user.zip || user.city) && <div className="address">
						<div>{user.street}</div>
						<div>{user.zip} {user.city}</div>
						<div><CountryLabel countryIso={user.countryIso} /></div>
					</div>}
					{(user.email || user.mobile) && <div className="contact">
						{user.email && <div><a href={`mailto:${user.email}`}>{user.email}</a></div>}
						{user.mobile && <div><a href={`tel:${user.mobile}`}>{user.mobile}</a></div>}
					</div>}
					{user.realtimeYield && <div className="realtimeYield">
						<N2Tuple label={<N2I18N2 id="client.teamgraph@pointsPersonal" def="VP Personal" />}><FormattedNumber value={user.realtimeYield.pointsPersonalTotal} /></N2Tuple>
						<N2Tuple label={<N2I18N2 id="client.teamgraph@pointsRankLevels" def="VP Rank Levels" />}><FormattedNumber value={user.realtimeYield.pointsRankLevels} /></N2Tuple>
					</div>}
				</div>}
			</div>}
	</div> : null
}



export function NetworkId({ user, displayNumber }) {
	return <span className="NetworkId">{user?.displayNumber || user?.deletedNumber || displayNumber}{user?.deleted && <span> ✝</span>}</span>
}


export function UserPhoto({ user, size, fallback }) {
	if (user?.photo) {
		return <N2Thumbnailer src={`${APP_API_URL}/pub/user/${user.uuid}/photo?d=${hash(user.photo)}`} size={size} square imgClass="UserPhoto" />
	} else if (fallback) {
		return <img src="/assets/noimg.jpg" style={{ width: size }} alt="none" className="UserPhoto fallback" />
	}
	return null
}

export function UserInfo({ user, labelFun }) {
	return <div className={classnames('UserInfo', {disabled: !user.enabled})}>
		<NetworkId user={user} />
		{/* {user.kycStatus !== 'VERIFIED' && <Icon fontSize="small" className="n2warning">warning</Icon>} */}
		<CountryFlag countryIso={user.countryIso} />
		{user.photo && <div className="photo"><UserPhoto user={user} size={24} /></div>}
		<div className="label">{labelFun(user)}</div>
		<Rank user={user} />
	</div>
}


function YieldInfo({ y }) {
	return y ? <div className="YieldInfo">
		<div><N2Tuple label={<N2I18N2 id="client.team@pointsPersonal" def="VP-P" />}><FormattedNumber value={y.pointsPersonal + y.pointsPersonalFromFirstline} /></N2Tuple></div>
		<div><N2Tuple label={<N2I18N2 id="client.team@pointsRankLevels" def="VP-R" />}><FormattedNumber value={y.pointsRankLevels} /></N2Tuple></div>
	</div> : null
}


export function CustomerInfo({ user }) {
	return <div className="CustomerInfo" style={{ opacity: user.enabled ? 1 : 0.5 }}>
		<NetworkId user={user} />
		<CountryFlag countryIso={user.countryIso} />
		<span>{user.name}</span>
	</div>
}


export function Rank({ user }) {
	for (let r = 9; r >= 0; r--) {
		if (array2date(user.ranks?.[r])) return <div className="Rank">
			<RankAvatar rank={r} />
			<span className="label"><N2I18N2 id={`client.common.rank@${APP_RANKS[r]}`} def={APP_RANKS[r].toUpperCase()} /></span>
		</div>
	}
	return null
}


export function CountryFlag({ countryIso }) {
	return countryIso
		? <img src={`/assets/flags/${countryIso.toLowerCase()}.svg`} className="n2flag" alt={countryIso} title={countryIso} loading="lazy" />
		: null
}


export function CountryLabel({ countryIso }) {
	const n2i18n2 = useN2I18N2()
	return countryIso ? COUNTRIES[n2i18n2.locale][countryIso] : null
}