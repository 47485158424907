import { Divider, Drawer, Hidden, Icon, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Subject } from 'rxjs';
import { APP_API_URL, LOCALES } from '../app/const';
import navigation from '../app/nav';
import { AppProvider } from '../n2shared/N2App';
import { N2I18N2, useN2I18N2 } from '../n2shared/N2I18N2';
import { array2date } from '../n2shared/n2functions';
import './AppNav.scss';
import { CountryFlag } from './Team';


const appNav = new Subject()

export const toggleDrawer = () => {
	appNav.next({ toggle: true })
}


export const AppNav = ({ impersonateLogout }) => {
	const { auth, isInRole, setLocale, logout } = useContext(AppProvider)
	const n2i18n2 = useN2I18N2()
	const [path, setPath] = useState('')
	const [drawerOpen, setDrawerOpen] = useState(false)

	const closeDrawer = callback => event => {
		setDrawerOpen(false)
		callback(event)
	}

	const urlToPath = useCallback(() => {
		try {
			setPath(navigation.getCurrentValue().url.pathname)
		} catch (error) {}
	}, [])

	useEffect(() => {
		urlToPath()
		navigation.subscribe(route => {
			urlToPath()
		})

		const subj = appNav.subscribe(msg => {
			if (msg.toggle) setDrawerOpen(!drawerOpen)
			else if (typeof msg.open === "boolean") setDrawerOpen(msg.open)
		})
		return () => subj.unsubscribe()
	}, [drawerOpen, urlToPath])

	const drawer = useMemo(() => {
		const MainMenuItem = ({ nav, sel, children }) => {
			return <ListItem button onClick={closeDrawer(() => navigation.navigate(nav))} selected={path.startsWith(sel || nav)}>
				{children}
			</ListItem>
		}

		const allFeatures = array2date(auth?.ranks?.[0])	// has at least START rank
		return <>
			<img src="/logo.svg" className="logo" alt="logo" loading="lazy" />
			<List>
				<ListItem>
					<Select value={n2i18n2.locale} onChange={(e, c) => setLocale(c.props.value)}>
						{Object.entries(LOCALES).map(([k, v]) => (
							<MenuItem key={k} value={k}>
								<CountryFlag countryIso={v.flag} /> {v.name}
							</MenuItem>
						))}
					</Select>
				</ListItem>
				{auth && <>
					<Divider />
					{auth.switchUser &&
						<ListItem button onClick={closeDrawer(impersonateLogout)}>
							<ListItemIcon><Icon className="n2impersonating">arrow_back</Icon></ListItemIcon>
							<ListItemText className="n2impersonating">{auth.switchUser.username}</ListItemText>
						</ListItem>
					}
					<MainMenuItem nav="/dashboard">
						<ListItemIcon><Icon>speed</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.dashboard@title" def="Home" /></ListItemText>
					</MainMenuItem>
					{allFeatures && <MainMenuItem nav="/finance/0" sel="/finance">
						<ListItemIcon><Icon>account_balance_wallet</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.finance@title" def="Finance" /></ListItemText>
					</MainMenuItem>}
					<MainMenuItem nav="/team">
						<ListItemIcon><Icon>group</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.team@title" def="Team" /></ListItemText>
					</MainMenuItem>
					{allFeatures && <MainMenuItem nav="/customers">
						<ListItemIcon><Icon>face</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.customers@title" def="Customers" /></ListItemText>
					</MainMenuItem>}
					<ListItem button onClick={closeDrawer(() => window.open(`${APP_API_URL}/wordpress/`))}>
						<ListItemIcon><Icon>shopping_cart</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.wordpress@title" def="Shop" /></ListItemText>
					</ListItem>
					{allFeatures && <MainMenuItem nav="/training">
						<ListItemIcon><Icon>school</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.training@title" def="Training" /></ListItemText>
					</MainMenuItem>}
					{allFeatures && <MainMenuItem nav="/infos">
						<ListItemIcon><Icon>import_contacts</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.infos@title" def="Infos" /></ListItemText>
					</MainMenuItem>}
					<MainMenuItem nav="/account">
						<ListItemIcon><Icon>account_box</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.account@title" def="Account" /></ListItemText>
					</MainMenuItem>
					<MainMenuItem nav="/support">
						<ListItemIcon><Icon>contact_support</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.support@title" def="Support" /></ListItemText>
					</MainMenuItem>
					<Divider />
					{isInRole('SUPERADMIN', 'ROOT') && <>
						<MainMenuItem nav="/admin/dash">
							<ListItemIcon><Icon>bar_chart</Icon></ListItemIcon>
							<ListItemText>Dash</ListItemText>
						</MainMenuItem>
						<MainMenuItem nav="/admin/payouts">
							<ListItemIcon><Icon>receipt</Icon></ListItemIcon>
							<ListItemText>Payouts</ListItemText>
						</MainMenuItem>
					</>}
					{isInRole('ADMIN', 'ROOT') &&
						<MainMenuItem nav="/admin/users">
							<ListItemIcon><Icon>people</Icon></ListItemIcon>
							<ListItemText>Users</ListItemText>
						</MainMenuItem>
					}
					{isInRole('I18N', 'ROOT') &&
						<MainMenuItem nav="/admin/i18n2">
							<ListItemIcon><Icon>translate</Icon></ListItemIcon>
							<ListItemText>I18N<sup>2</sup></ListItemText>
						</MainMenuItem>
					}
					{isInRole('CMS', 'ROOT') &&
						<MainMenuItem nav="/admin/cms">
							<ListItemIcon><Icon>pages</Icon></ListItemIcon>
							<ListItemText>CMS</ListItemText>
						</MainMenuItem>
					}
					{isInRole('NODELOG', 'ROOT') && <>
						<MainMenuItem nav="/admin/nodelog">
							<ListItemIcon><Icon>view_list</Icon></ListItemIcon>
							<ListItemText>NodeLog</ListItemText>
						</MainMenuItem>
					</>}
					<Divider />
					<ListItem button onClick={closeDrawer(logout)}>
						<ListItemIcon><Icon>close</Icon></ListItemIcon>
						<ListItemText><N2I18N2 id="client.logout@title" def="Log Out" /></ListItemText>
					</ListItem>
				</>}
				{isInRole('ROOT') && <ListItem>
					<ListItemText>v {process.env.REACT_APP_VERSION}</ListItemText>
				</ListItem>}
			</List>
		</>
	}, [auth, impersonateLogout, isInRole, logout, n2i18n2.locale, path, setLocale])

	return <nav className="AppNav">
		<Hidden lgUp implementation="css">
			<Drawer open={drawerOpen} onClose={e => setDrawerOpen(!drawerOpen)}
					ModalProps={{ keepMounted: true }}
					variant="temporary">
				{drawer}
			</Drawer>
		</Hidden>
		<Hidden mdDown implementation="css">
			<Drawer open variant="permanent">
				{drawer}
			</Drawer>
		</Hidden>
	</nav>
}
