import { Avatar, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Menu, MenuItem, TextField } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import ChipInput from 'material-ui-chip-input';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useNavigation } from 'react-navi';
import { APP_API_URL } from '../app/const';
import { N2Busy } from './N2Busy';
import './N2CMS.scss';
import { useN2Confirm } from './N2Confirm2';
import N2Dropzone from './N2Dropzone';
import { n2objectFeed } from './N2EventFeed';
import N2Skeleton from './N2Skeleton';
import { showSnackbar } from './N2Snackbar';
import N2Thumbnailer from './N2Thumbnailer';
import { N2Content, N2Toolbar, N2View } from './N2View';
import { useRequireRole } from './n2appHooks';
import { devlog, inputError } from './n2functions';
import { useAxiosGet, useLocalStorage, useSwitch } from './n2hooks';


export const N2CMS_THUMBSIZES = [
	{
		media: '',
		densities: { '1x': 50, '2x': 100, '3x': 150, '4x': 200 }
	},
]

export const N2CMS_FEATURESIZES = [
	{
		media: '(min-width: 1280px)',
		densities: { '1x': 1200 }
	},
	{
		media: '(min-width: 960px) and (max-width: 1279px)',
		densities: { '1x': 900, '2x': 1200 }
	},
	{
		media: '(min-width: 600px) and (max-width: 959px)',
		densities: { '1x': 600, '2x': 900, '3x': 1200 }
	},
	{
		media: '(max-width: 599px)',
		densities: { '1x': 300, '2x': 600, '3x': 900, '4x': 1200 }
	},
]


export const N2AdminCMSPages = () => {
	useRequireRole(['CMS', 'ROOT'])

	const navigation = useNavigation()
	const [filter, setFilter] = useLocalStorage('cmsadmin.filter', { search: '*' })
	const { register, handleSubmit } = useForm()
	const [showingAdd, toggleAdd] = useSwitch(false)

	const onSubmit = d => {
		setFilter(d)
	}

	const edit = useCallback(page => {
		navigation.navigate(`/admin/cms/${page.id}`)
	}, [navigation])

	const add1 = useCallback(() => {
		toggleAdd()
	}, [toggleAdd])
	const add2 = useCallback(d => {
		toggleAdd()
		axios.post(`${APP_API_URL}/rest/admin/cmspages`, d).then(r => {
			navigation.navigate(`/admin/cms/${r.data.id}`)
		})
	}, [navigation, toggleAdd])

	const [pages, pagesLoading] = useAxiosGet(`${APP_API_URL}/rest/admin/cmspages?${new URLSearchParams(filter)}`, [filter])

	return <N2View className="N2CMS">
		<N2Toolbar left={<h3>CMS</h3>}>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<TextField name="search" inputRef={register} defaultValue={filter.search} placeholder="Search" margin="dense" />
				<IconButton type="submit"><Icon>search</Icon></IconButton>
			</form>
		</N2Toolbar>
		<Fab onClick={add1} color="primary"><Icon>add</Icon></Fab>
		{pagesLoading && <N2Busy>Loading...</N2Busy>}
		{pages && <N2Content>
			<List>
				{pages.map(p => <ListItem button key={p.id} onClick={e => edit(p)}>
					{p.featuredImage && <ListItemAvatar>
						<Avatar><N2Thumbnailer src={`${APP_API_URL}/pub/cmsimage/${p.featuredImage.uid}?t=${p.featuredImage.modified}`} sizes={N2CMS_THUMBSIZES} size={50} square /></Avatar>
					</ListItemAvatar>}
					<ListItemText primary={
						<>
						{p.published && <Icon fontSize="small" style={{color: green[500]}}>check</Icon>}
						{p.name}
						<span className="category">{p.category}</span>
						</>
					} secondary={
						<>
						{p?.files?.length>0 && <Icon className="attachment">attachment</Icon>}
						{p.summary}
						{p.tags && p.tags.map((t, i) => <span key={i} className="tag">{t}</span>)}
						</>
					} />
				</ListItem>)}
			</List>
		</N2Content>}
		<AdminCMSAddPageDialog showing={showingAdd} hide={toggleAdd} onSubmit={add2} />
	</N2View>
}

const AdminCMSAddPageDialog = ({ showing, hide, onSubmit}) => {
	const { register, handleSubmit } = useForm()

	return showing ? createPortal(
		<Dialog open={showing} onClose={hide}>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<DialogTitle>Add</DialogTitle>
				<DialogContent>
					<TextField name="category" inputRef={register} label="Category" autoFocus margin="dense" fullWidth/>
					<TextField name="name" inputRef={register} label="Name" margin="dense" fullWidth/>
				</DialogContent>
				<DialogActions>
					<Button onClick={hide}>Cancel</Button>
					<Button type="submit" color="primary">Add</Button>
				</DialogActions>
			</form>
		</Dialog>, document.body
	) : null
}


export const N2AdminCMSPage = ({ pageId }) => {
	useRequireRole(['CMS', 'ROOT'])

	const navigation = useNavigation()
	const n2confirm = useN2Confirm()
	const [page, setPage] = useState()
	const [edit, setEdit] = useState({})
	const [menuAnchor, setMenuAnchor] = useState()
	const { register, handleSubmit, errors, formState, reset } = useForm()
	const tiny = useRef()

	const setResponse = useCallback((r, set) => {
		setPage(r.data)
		setEdit({
			dirty: false,
			tags: r.data.tags,
			text: r.data.text
		})
		if (set) {
			reset(r.data)
			tiny.current.editor.setContent(r.data.text)
		}
	}, [reset])

	const loadPage = useCallback(set => {
		axios.get(`${APP_API_URL}/rest/admin/cmspages/${pageId}`).then(r => {
			setResponse(r, set)
		})
	}, [pageId, setResponse])

	const handleTagsChange = useCallback(tags => {
		devlog('tags', tags)
		setEdit(prev => ({ ...prev, tags, dirty: true }))
	}, [])

	const handleEditorChange = useCallback((content, editor) => {
		setEdit(prev => ({ ...prev, text: content, dirty: true }))
		devlog('tiny', tiny.current.editor.getContent());
	}, [])

	const onSubmit = useCallback(d => {
		d.tags = edit.tags
		d.text = edit.text
		axios.patch(`${APP_API_URL}/rest/admin/cmspages/${pageId}`, d).then(r => {
			setResponse(r, true)
			showSnackbar('saved')
		})
	}, [edit.tags, edit.text, pageId, setResponse])

	const openImg = useCallback(image => {
		window.open(`${APP_API_URL}/pub/cmsimage/${image.uid}`)
	}, [])
	const openFile = useCallback(file => {
		window.open(`${APP_API_URL}/pub/cmsfiles/${file.uid}`)
	}, [])

	const delFile = useCallback(f => {
		axios.delete(`${APP_API_URL}/rest/admin/cmspages/${pageId}/files/${f.id}`).then(r => {
			setResponse(r)
			showSnackbar('deleted')
		})
	}, [pageId, setResponse])

	const back = useCallback(() => {
		navigation.navigate('/admin/cms')
	}, [navigation])

	const delPage = useCallback(e => {
		setMenuAnchor(null)
		n2confirm({ text: 'Really delete this page?' }).then(() => {
			axios.delete(`${APP_API_URL}/rest/admin/cmspages/${pageId}`).then(r => {
				back()
			})
		})
	}, [back, n2confirm, pageId])

	const delImg = useCallback(() => {
		axios.delete(`${APP_API_URL}/rest/admin/cmspages/${pageId}/featuredImage`).then(r => {
			setResponse(r)
			showSnackbar('deleted')
		})
	}, [pageId, setResponse])

	const handleFileUploaded = useCallback(r => {
		setResponse(r)
		showSnackbar('saved')
	}, [setResponse])

	useEffect(() => {
		loadPage()
	}, [loadPage])

	useEffect(() => {
		devlog("CMSAdminPage subscribing to n2objectFeed")
		const ofs = n2objectFeed.subscribe({
			next: msg => {
				devlog('CMSAdminPage objectFeed', msg)
				if (msg.type==='CmsPage' && msg.id===+pageId && msg.modified>page.modified) {
					switch (msg.action) {
						case 'deleted': back(); break
						case 'updated': setEdit(prev => ({ ...prev, stale: true })); break
						default:
					}
				}
			}
		})
		return () => {
			devlog("CMSAdminPage unsubscribing from n2objectFeed")
			ofs.unsubscribe()
		}
	}, [back, page, pageId])

	return page ? <N2View className="N2CMS">
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<N2Toolbar left={<IconButton edge="start" onClick={back}><Icon>close</Icon></IconButton>}>
				<Button type="submit" variant={edit.dirty||formState.isDirty ? 'contained' : 'outlined'} color="primary" disableElevation>Save</Button>
				<IconButton edge="end" onClick={e => setMenuAnchor(e.currentTarget)}><Icon>more_vert</Icon></IconButton>
			</N2Toolbar>
			<Menu keepMounted anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={e => setMenuAnchor(null)}>
				<MenuItem onClick={delPage}>Delete</MenuItem>
				<MenuItem onClick={e => {navigator.clipboard.writeText(page.uid)}}>Copy UID</MenuItem>
			</Menu>
			{edit.stale && <div className="n2topAlert">
				<Alert variant="filled" severity="warning" action={<Button onClick={e => loadPage(true)}>LOAD</Button>}>Stale Data!</Alert>
			</div>}
			<N2Content className="grid">
				<TextField name="category" defaultValue={page.category} inputRef={register({required: true})}
					label="Category" fullWidth {...inputError(errors,'category')} className="grid_category" />
				<FormControlLabel control={
					<Checkbox name="published" inputRef={register} defaultChecked={page.published} color="default" />
				} label="Published" className="grid_published" />
				<div className="grid_image">
					Featured Image
					{page.featuredImage && <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
						<Grid item onClick={e => openImg(page.featuredImage)}>
							<N2Thumbnailer src={`${APP_API_URL}/pub/cmsimage/${page.featuredImage.uid}?t=${page.featuredImage.modified}`} size={100} imgClass="n2pointer" />
						</Grid>
						<Grid item>
							<IconButton onClick={delImg}><Icon>delete</Icon></IconButton>
						</Grid>
					</Grid>}
					<N2Dropzone url={`${APP_API_URL}/rest/admin/cmspages/${pageId}/featuredImage`} accept="image/jpeg" onUploaded={handleFileUploaded} />
				</div>
				<TextField name="name" defaultValue={page.name} inputRef={register({required: true})}
					label="Name" fullWidth {...inputError(errors,'name')} className="grid_name" />
				<TextField name="summary" defaultValue={page.summary} inputRef={register}
					label="Summary" fullWidth {...inputError(errors,'summary')} className="grid_summary" />
				<ChipInput name="tags" defaultValue={page.tags} onChange={handleTagsChange}
					label="Tags" fullWidth variant="outlined" className="grid_tags" />
				<div className="grid_text">
					<Editor initialValue={page.text} onEditorChange={handleEditorChange} ref={tiny}
					 	apiKey={process.env.REACT_APP_TINYMCE_APIKEY} init={{
							height: 400,
							plugins: ['lists', 'code', 'paste', 'link'],
							skin_url: document.location.origin + '/tinymce',
							toolbar: `undo redo | formatselect | bold italic |
								alignleft aligncenter alignright alignjustify |
								bullist numlist outdent indent | link | removeformat code`,
							paste_block_drop: true }}/>
				</div>
				<div className="grid_files">
					<N2Dropzone url={`${APP_API_URL}/rest/admin/cmspages/${pageId}/files`} paramName="files" onUploaded={handleFileUploaded} />
					{page.files && <List>
						{page.files.map(f => <ListItem key={f.id} button onClick={e => openFile(f)}>
							{f.thumbnailPath && <ListItemAvatar>
								<Avatar src={`${APP_API_URL}/pub/cmsfiles/${f.uid}/thumbnail`}/>
							</ListItemAvatar>}
							{f.contentType.match('^image/.*') && <ListItemAvatar>
								<Avatar><N2Thumbnailer src={`${APP_API_URL}/pub/cmsfiles/${f.uid}?t=${f.modified}`} size={50} square /></Avatar>
							</ListItemAvatar>}
							<ListItemText primary={f.name} secondary={
								<>
								{f.contentType && <span className="tag">{f.contentType}</span>}
								{(f.width && f.width>0) ? <span className="tag">{f.width} x {f.height}</span> : ''}
								{(f.duration && f.duration>0) ? <span className="tag">{f.duration} sec</span> : ''}
								</>
							} />
							<ListItemSecondaryAction>
								<IconButton edge="end" onClick={e => delFile(f)}><Icon>delete</Icon></IconButton>
							</ListItemSecondaryAction>
						</ListItem>)}
					</List>}
				</div>
			</N2Content>
		</form>
	</N2View> : null
}


export const N2CMSPages = ({ category, onShowPage }) => {
	useRequireRole('USER')

	const { register, handleSubmit } = useForm()
	const [filter, setFilter] = useLocalStorage(`cms.filter.${category}`, { category, search: '' })

	const onSubmit = useCallback(d => {
		setFilter({ category, ...d })
	}, [category, setFilter])

	const [pages, pagesLoading] = useAxiosGet(`${APP_API_URL}/rest/cmspages?${new URLSearchParams(filter)}`, [filter])

	useEffect(() => {
		setFilter(prev => ({ ...prev, category }))
	}, [category, setFilter])

	return <N2View className="N2CMS">
		<N2Toolbar>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<TextField name="search" inputRef={register} defaultValue={filter.search} placeholder="Search" margin="dense"
					InputProps= {{
						endAdornment: <InputAdornment position="end"><IconButton type="submit"><Icon>search</Icon></IconButton></InputAdornment>
					}} />
			</form>
		</N2Toolbar>
		<N2Content>
			{pages && <List>
				{pages.map(p => <ListItem button key={p.id} onClick={e => onShowPage(p)}>
					{p.featuredImage && <ListItemAvatar>
						<Avatar><N2Thumbnailer src={`${APP_API_URL}/pub/cmsimage/${p.featuredImage.uid}?t=${p.featuredImage.modified}`} sizes={N2CMS_THUMBSIZES} size={50} square /></Avatar>
					</ListItemAvatar>}
					<ListItemText primary={p.name} secondary={<>
						{p?.files?.length>0 && <Icon className="attachment">attachment</Icon>}
						{p.summary}
						{p.tags && p.tags.map((t, i) => <span key={i} className="tag">{t}</span>)}
					</>} />
				</ListItem>)}
			</List>}
			{pagesLoading && <N2Skeleton type="list" lines={5} pattern="avatar grow" />}
		</N2Content>
	</N2View>
}


export const N2CMSPage = ({ uid, onBack }) => {
	useRequireRole('USER')

	const [page, pageLoading] = useAxiosGet(uid && `${APP_API_URL}/rest/cmspage/${uid}`, [uid])

	return <N2View className="N2CMS">
		<N2Toolbar left={<IconButton edge="start" onClick={onBack}><Icon>close</Icon></IconButton>} />
		<N2Content className="N2CMSPage">
			{pageLoading && <N2Skeleton type="text" pattern="image title 3-line-100% 1-line-80% 2-line-100% 1-line-60%" />}
			{page && <N2RenderCmsPage page={page.cmsPage} />}
		</N2Content>
	</N2View>
}


export const N2RenderCmsPage = ({ page, name, openFile = defaultOpenFile }) => {
	return page ? <>
		{page.featuredImage
			? <header>
				<div className="featuredImage">
					<N2Thumbnailer src={`${APP_API_URL}/pub/cmsimage/${page.featuredImage.uid}?t=${page.featuredImage.modified}`} sizes={N2CMS_FEATURESIZES} size={900} alt="featured" />
				</div>
				<div className="name"><h1>{name || page.name}</h1></div>
			</header>
			: <h1>{name || page.name}</h1>}
		<div dangerouslySetInnerHTML={{__html: mergeCmsPageText(page)}} className="text"></div>
		{page.files && <List>
			{page.files.map(f => <ListItem button key={f.id} onClick={e => openFile(f)}>
				{f.thumbnailPath && <ListItemAvatar>
					<Avatar src={`${APP_API_URL}/pub/cmsfiles/${f.uid}/thumbnail`}/>
				</ListItemAvatar>}
				{f.contentType.match('^image/.*') && <ListItemAvatar>
					<Avatar><N2Thumbnailer src={`${APP_API_URL}/pub/cmsfiles/${f.uid}?t=${f.modified}`} sizes={N2CMS_THUMBSIZES} size={50} square /></Avatar>
				</ListItemAvatar>}
				{(!f.thumbnailPath && !f.contentType.match('^image/.*')) && <ListItemAvatar>
					<Avatar><Icon>attachment</Icon></Avatar>
				</ListItemAvatar>}
				<ListItemText primary={f.name} secondary={
					<>
					{(f.width && f.width>0) ? <span className="tag">{f.width} x {f.height}</span> : ''}
					{(f.duration && f.duration>0) ? <span className="tag">{f.duration} sec</span> : ''}
					</>
				} />
			</ListItem>)}
		</List>}
	</> : null
}


function defaultOpenFile(file) {
	window.open(`${APP_API_URL}/pub/cmsfiles/${file.uid}`)
}


export const mergeCmsPageText = page => {
	let merged = page.text;
	page.files && page.files.forEach(f => {
		let replace = null
		const src = `${APP_API_URL}/pub/cmsfiles/${f.uid}`
		if (f.contentType.match('^image/.*')) {
			replace = `<img src="${src}" />`
		} else if (f.contentType.match('^video/.*')) {
			const poster = f.posterPath ? `${APP_API_URL}/pub/cmsfiles/${f.uid}/poster` : `${APP_API_URL}/pub/cmsfiles/${f.uid}/thumbnail`
			replace = `<video src="${src}" type="${f.contentType}" poster="${poster}" preload="metadata" controls="controls"></video>`
		}
		if (replace) merged = merged.replace(`{${f.uid}}`, replace)
	})
	return merged
}
