import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { APP_API_URL } from '../app/const';
import { N2Busy } from './N2Busy';
import { n2eventFeed } from './N2EventFeed';
import N2Expiry from './N2Expiry';
import { devlog } from './n2functions';
import { N2I18N2 } from './N2I18N2';


const AuthDialogContext = createContext(Promise.reject)


export const N2AuthDialogProvider = ({ children }) => {
	const { register, handleSubmit } = useForm()
	const [state, setState] = useState()
	const [info, setInfo] = useState()
	const [expires, setExpires] = useState()
	const efs = useRef()
	const promiseRef = useRef()

	const authTelegramClear = () => {
		axios.post(`${APP_API_URL}/rest/user/authTelegram/clear`, {})
	}

	const authenticate = config => {
		devlog(config)
		setState(config)
		setInfo(<N2Busy />)
		setExpires(null)
		if (config.authType === 'EMAIL') {
			function setInfoEmail(email) {
				setInfo(<Typography>
					<N2I18N2 id="client.authDialog@enterEmailCode" def="Check {email}" values={{ email }} />
				</Typography>)
			}
			if (config.email == null) {
				axios.get(`${APP_API_URL}/rest/user/authEmail`).then(r => {
					setInfoEmail(r.data.email);
				})
			} else {
				setInfoEmail(config.email);
			}
		} else if (config.authType === 'TOTP') {
			setInfo(<Typography>
				<N2I18N2 id="client.authDialog@enterTotpCode" def="Code from Authenticator App" />
			</Typography>)
		} else if (config.authType === 'TELEGRAM') {
			axios.get(`${APP_API_URL}/rest/user/authTelegram`).then(r => {
				setInfo(<Typography>
					<N2I18N2 id="client.authDialog@confirmInTelegram"
						def="Confirm the code <strong>{authMatchCode}</strong> in Telegram!"
						values={r.data} html />
				</Typography>)
				setExpires(r.data.authMatchCodeExpiry)
				efs.current = n2eventFeed.subscribe(msg => {
					devlog('N2AuthDialogProvider n2eventFeed', msg)
					if (msg.event === 'telegram.authTelegram.confirmed') {
						onSubmit({ auth: msg.authMatchCode })
					}
				})
				window.addEventListener('beforeunload', authTelegramClear)
			})
		}

		return new Promise((resolve, reject) => {
            promiseRef.current = { resolve, reject }
        })
    }

	const onClose = () => {
		window.removeEventListener('beforeunload', authTelegramClear)
		authTelegramClear()
		if (state.catchOnCancel && promiseRef.current) promiseRef.current.reject()
		setState(null)
	}

	const onSubmit = data => {
		window.removeEventListener('beforeunload', authTelegramClear)
		if (promiseRef.current) promiseRef.current.resolve(data.auth)
        setState(false)
	}

	useEffect(() => {
		return () => {
			if (efs.current) efs.current.unsubscribe()
		}
	}, [])

	return <>
		<AuthDialogContext.Provider value={authenticate} children={children} />

		<Dialog open={Boolean(state)} onClose={onClose}>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<DialogTitle>
					<N2I18N2 id="client.authDialog@title" def="Authenticate"/>
				</DialogTitle>
				<DialogContent>
					<Grid container direction="column" spacing={2}>
						<Grid item>{info}</Grid>
						{state?.authType !== 'TELEGRAM' && <Grid item>
							<TextField name="auth" inputRef={register} label="Code" autoFocus margin="dense" fullWidth autoComplete="off"/>
						</Grid>}
						{expires && <Grid item>
							<N2I18N2 id="client.authDialog@expires" def="Expires in"/> <N2Expiry when={expires} onExpiry={onClose} />
						</Grid>}
					</Grid>
				</DialogContent>
				<DialogActions>
					{state?.authType !== 'TELEGRAM' && <Button type="submit"><N2I18N2 id="client.authDialog@submit" def="Send"/></Button>}
				</DialogActions>
			</form>
		</Dialog>
	</>
}


export const useN2AuthDialog = () => useContext(AuthDialogContext)
