// from https://easings.net/

const EASING_FUNCTIONS = {
	linear: n => n,

	inSine: n => 1 - Math.cos((n * Math.PI) / 2),
	inQuad: n => n * n,
	inCubic: n => n * n * n,
	inQuart: n => n * n * n * n,
	inQuint: n => n * n * n * n * n,
	inExpo: n => Math.pow(2, 10 * (n - 1)),
	inCirc: n => 1 - Math.sqrt(1 - Math.pow(n, 2)),
	inBack: n => {
		const c1 = 1.70158, c3 = c1 + 1
		return c3 * n * n * n - c1 * n * n
	},
	inElastic: n => {
		const c4 = (2 * Math.PI) / 3
		return n === 0 ? 0 : n === 1 ? 1 : -Math.pow(2, 10 * n -10) * Math.sin((n * 10 - 10.75) * c4)
	},
	inBounce: null, // set below

	outSine: n => Math.sin((n * Math.PI) / 2),
	outQuad: n => 1 - (1 - n) * (1 - n),
	outCubic: n => 1 - Math.pow(1 - n, 3),
	outQuart: n => 1 - Math.pow(1 - n, 4),
	outQuint: n => 1 - Math.pow(1 - n, 5),
	outExpo: n => n === 1 ? 1 : 1 - Math.pow(2, -10 * n),
	outCirc: n => Math.sqrt(1 - Math.pow(n - 1, 2)),
	outBack: n => {
		const c1 = 1.70158, c3 = c1 + 1
		return 1 + c3 * Math.pow(n - 1, 3) + c1 * Math.pow(n - 1, 2)
	},
	outElastic: n => {
		const c4 = (2 * Math.PI) / 3
		return n === 0 ? 0 : n === 1 ? 1 : Math.pow(2, -10 * n) * Math.sin((n * 10 - 0.75) * c4) + 1
	},
	outBounce: n => {
		const n1 = 7.5625
		const d1 = 2.75
		if (n < 1 / d1) {
			return n1 * n * n
		} else if (n < 2 / d1) {
			return n1 * (n -= 1.5 / d1) * n + 0.75
		} else if (n < 2.5 / d1) {
			return n1 * (n -= 2.25 / d1) * n + 0.9375
		} else {
			return n1 * (n -= 2.625 / d1) * n + 0.984375
		}
	},

	inOutSine: n => -(Math.cos(Math.PI * n) - 1) / 2,
	inOutQuad: n => n < 0.5 ? 2 * n * n : 1 - Math.pow(-2 * n + 2, 2) / 2,
	inOutCubic: n => n < 0.5 ? 4 * n * n * n : 1 - Math.pow(-2 * n + 2, 3) / 2,
	inOutQuart: n => n < 0.5 ? 8 * n * n * n * n : 1 - Math.pow(-2 * n + 2, 4) / 2,
	inOutQuint: n => n < 0.5 ? 16 * n * n * n * n * n : 1 - Math.pow(-2 * n + 2, 5) / 2,
	inOutExpo: n => n === 0 ? 0 : n === 1 ? 1 : n < 0.5
		? Math.pow(2, 20 * n - 10) / 2
		: (2 - Math.pow(2, -20 * n + 10)) / 2,
	inOutCirc: n => n < 0.5
		? (1 - Math.sqrt(1 - Math.pow(2 * n, 2))) / 2
		: (Math.sqrt(1 - Math.pow(-2 * n + 2, 2)) + 1) / 2,
	inOutBack: n => {
		const c1 = 1.70158, c2 = c1 * 1.525
		return n < 0.5
			? (Math.pow(2 * n, 2) * ((c2 + 1) * 2 * n - c2)) / 2
			: (Math.pow(2 * n - 2, 2) * ((c2 + 1) * (n * 2 - 2) + c2) + 2) / 2
	},
	inOutElastic: n => {
		const c5 = (2 * Math.PI) / 4.5
		return n === 0 ? 0 : n === 1 ? 1 : n < 0.5
			? -(Math.pow(2, 20 * n - 10) * Math.sin((20 * n - 11.125) * c5)) / 2
			: (Math.pow(2, -20 * n + 10) * Math.sin((20 * n - 11.125) * c5)) / 2 + 1
	},
	inOutBounce: null, // set below
}

EASING_FUNCTIONS.inBounce = n => 1 - EASING_FUNCTIONS.outBounce(1 - n)

EASING_FUNCTIONS.inOutBounce = n => n < 0.5
	? (1 - EASING_FUNCTIONS.outBounce(1 - 2 * n)) / 2
	: (1 + EASING_FUNCTIONS.outBounce(2 * n - 1)) / 2


export default EASING_FUNCTIONS

