import { Avatar, Box, Button, Grid, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedDate } from 'react-intl'
import { useNavigation } from 'react-navi'
import { useQuery } from 'react-query'
import { APP_API_URL } from '../app/const'
import { useUser } from '../n2shared/N2App'
import { N2CMS_THUMBSIZES, N2RenderCmsPage } from '../n2shared/N2CMS'
import { N2I18N2 } from '../n2shared/N2I18N2'
import N2Skeleton from '../n2shared/N2Skeleton'
import N2Thumbnailer from '../n2shared/N2Thumbnailer'
import { N2Content, N2Toolbar, N2View } from '../n2shared/N2View'
import { useRequireRole } from '../n2shared/n2appHooks'
import { array2date, floor2 } from '../n2shared/n2functions'
import { NetworkId } from './Team'
import './Training.scss'


export default function Training({ pageUid }) {
	useRequireRole('USER')

	const navigation = useNavigation()
	const { data: user } = useUser()

	const show = useCallback(page => {
		navigation.navigate(`/training/${page.uid}`)
	}, [navigation])

	const back = useCallback(() => {
		navigation.navigate(`/training`)
	}, [navigation])

	const category = `T1-${user?.languageIso || 'de'}`
	return pageUid
		? <TrainingPage category={category} uid={pageUid} onBack={back} />
		: <TrainingPages category={category} onShowPage={show} />
}


function TrainingPages({ category, onShowPage }) {
	const { data: user } = useUser()
	const { data: pages, isLoading } = useQuery(['training', 'pages', category], async () => {
		return (await axios.get(`${APP_API_URL}/rest/training/${category}/pages`)).data
	})
	const [entries, setEntries] = useState()
	const [completed, setCompleted] = useState()

	useEffect(() => {
		if (!pages) return
		let todoFound = false
		let completedCount = 0
		setEntries(pages.map((page, index) => {
			const entry = { ...page, cleanName: cleanName(page.page.name),  listItemProps: {} }
			if (!!entry.watched?.started) {
				entry.started = true
				entry.listItemProps.className = 'started'
			}
			if (!!entry.watched?.completed) {
				entry.completed = true
				entry.listItemProps.className = 'completed'
				completedCount++
			}
			entry.clickable = index === 0 || entry.watched?.completed || !!pages[index - 1].watched?.completed
			if (entry.clickable) {
				entry.listItemProps.button = true
				entry.listItemProps.onClick = e => onShowPage(entry.page)
				if (!todoFound && !entry.watched?.completed) {
					entry.todo = true
					entry.listItemProps.className = 'todo'
					todoFound = true
				}
			}
			return entry
		}))
		setCompleted({
			total: pages.length,
			completedCount,
			completedPct: Math.round(100.0 * completedCount / pages.length)
		})
	}, [onShowPage, pages])

	return <N2View className="N2CMS TrainingPages">
		<N2Toolbar left={<h3>Training ({category}) <NetworkId user={user} /></h3>}>
			{completed && <Box display="flex" alignItems="center" gridGap={8}>
				<TrainingProgress size={48} pct={completed.completedPct} />
			</Box>}
		</N2Toolbar>
		<N2Content>
			{isLoading && <N2Skeleton type="list" lines={5} pattern="avatar grow" />}
			{entries && <List>
				{entries.map((e, i) => <ListItem key={e.page.id} {...e.listItemProps}>
					{e.page.featuredImage && <ListItemAvatar>
						<Avatar><N2Thumbnailer src={`${APP_API_URL}/pub/cmsimage/${e.page.featuredImage.uid}?t=${e.page.featuredImage.modified}`} sizes={N2CMS_THUMBSIZES} size={50} square /></Avatar>
					</ListItemAvatar>}
					<ListItemText primary={e.cleanName}
						secondary={<>
							{e.page.files?.length>0 && <Icon className="attachment">attachment</Icon>}
							{e.page.summary}
							{e.page.tags?.map((t, i) => <span key={i} className="tag">{t}</span>)}
						</>} />
					<ListItemSecondaryAction>
						{e.started && !e.completed && <Icon color="disabled">pause_circle</Icon>}
						{e.completed && <Icon color="primary">check_circle</Icon>}
					</ListItemSecondaryAction>
				</ListItem>)}
			</List>}
		</N2Content>
	</N2View>
}


function TrainingPage({ category, uid, onBack }) {
	const { data: page, isLoading } = useQuery(['training', 'page', uid], async () => {
		return (await axios.get(`${APP_API_URL}/rest/training/${category}/pages/${uid}`)).data
	})
	const [canComplete, setCanComplete] = useState(false)

	const postStatus = useCallback(status => {
		axios.post(`${APP_API_URL}/rest/training/${uid}/status/${status}`).then(r => {
			if (r.data.completed) onBack()
		})
	}, [onBack, uid])

	useEffect(() => {
		if (!page) return
		if (!page.watched?.started) {
			postStatus('started')
		}
		if (!page.watched?.completed) {
			setTimeout(() => setCanComplete(true), page?.watched?.started ? 5_000 : 30_000)
		}
	}, [page, postStatus])

	return <N2View className="N2CMS TrainingPage">
		<N2Toolbar left={<Box>
				<IconButton edge="start" onClick={onBack}><Icon>close</Icon></IconButton>
				{page && <h3>{category}-{page.page.name.replace(/^([0-9]{1,5}) .*/, '$1')}</h3>}
			</Box>}>
			{canComplete && <Button onClick={e => postStatus('completed')} color="primary" variant="contained" startIcon={<Icon>check</Icon>}>
				<N2I18N2 id="client.training.setCompleted@button" def="DONE" />
			</Button>}
		</N2Toolbar>
		<N2Content className="N2CMSPage">
			{isLoading && <N2Skeleton type="text" pattern="image title 3-line-100% 1-line-80% 2-line-100% 1-line-60%" />}
			{page && <>
				<N2RenderCmsPage page={page.page} name={cleanName(page.page.name)} />
				{canComplete && <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gridGap={8}>
					<N2I18N2 id="client.training.setCompleted@info" def="After completing this training step, press the button:" />
					<Button onClick={e => postStatus('completed')} color="primary" variant="contained" startIcon={<Icon>check</Icon>}>
						<N2I18N2 id="client.training.setCompleted@button" def="DONE" />
					</Button>
				</Box>}
				{page.watched?.completed && <Grid container justifyContent="center">
					<Grid item><Alert severity="success">
					<N2I18N2 id="client.training.completed@info"
						def="You completed this training step on {completedDate} at {completedTime}"
						values={{
							completedDate: <FormattedDate value={array2date(page.watched.completed)} />,
							completedTime: <FormattedDate value={array2date(page.watched.completed)} format="timeHM" />
						}} />
					</Alert></Grid>
				</Grid>}
			</>}
		</N2Content>
	</N2View>
}


function cleanName(name) {
	const m = name.match(/^([0-9]{1,5}) (.+)$/)
	return m ? m[2] : name
}


export const TrainingProgress = ({ size, pct, withLabel = true }) => {
	pct = Math.max(0, Math.min(pct, 100)) || 0
	const c = 239
	const o = floor2(((100 - pct) / 100) * c)

	return <svg width={size} height={size} viewBox="0 0 100 100" className="TrainingProgress" xmlns="http://www.w3.org/2000/svg">
		<circle cx="50" cy="50" className="bg" r="38" strokeWidth="20"/>
		<circle cx="50" cy="50" className="fg" r="38" strokeWidth="20" strokeDasharray={c} strokeDashoffset={o} transform="rotate(-90,50,50)" />
		{withLabel && <text x={50} y={50} className="label">{Math.floor(pct)}%</text>}
	</svg>
}
