import { withStyles } from '@material-ui/core';


const cssVariables = theme => ({
	'@global': {
		':root': {
			'--spacing05': `${theme.spacing(0.5)}px`,
			'--spacing': `${theme.spacing(1)}px`,
			'--spacing2': `${theme.spacing(2)}px`,
			'--spacing3': `${theme.spacing(3)}px`,

			'--typography-body1-fontSize': theme.typography.body1.fontSize,

			'--palette-primary-main': theme.palette.primary.main,
			'--palette-primary-dark': theme.palette.primary.dark,
			'--palette-primary-light': theme.palette.primary.light,
			'--palette-primary-contrastText': theme.palette.primary.contrastText,

			'--palette-secondary-main': theme.palette.secondary.main,
			'--palette-secondary-dark': theme.palette.secondary.dark,
			'--palette-secondary-light': theme.palette.secondary.light,
			'--palette-secondary-contrastText': theme.palette.secondary.contrastText,

			'--palette-success-main': theme.palette.success.main,
			'--palette-success-dark': theme.palette.success.dark,
			'--palette-success-light': theme.palette.success.light,
			'--palette-success-contrastText': theme.palette.success.contrastText,

			'--palette-info-main': theme.palette.info.main,
			'--palette-info-dark': theme.palette.info.dark,
			'--palette-info-light': theme.palette.info.light,
			'--palette-info-contrastText': theme.palette.info.contrastText,

			'--palette-warning-main': theme.palette.warning.main,
			'--palette-warning-dark': theme.palette.warning.dark,
			'--palette-warning-light': theme.palette.warning.light,
			'--palette-warning-contrastText': theme.palette.warning.contrastText,

			'--palette-error-main': theme.palette.error.main,
			'--palette-error-dark': theme.palette.error.dark,
			'--palette-error-light': theme.palette.error.light,
			'--palette-error-contrastText': theme.palette.error.contrastText,

			'--palette-background-default': theme.palette.background.default,
			'--palette-background-paper': theme.palette.background.paper,
			'--palette-background-default-contrast': theme.palette.getContrastText(theme.palette.background.default),
			'--palette-background-paper-contrast': theme.palette.getContrastText(theme.palette.background.paper),

			'--palette-text-primary': theme.palette.text.primary,
			'--palette-text-secondary': theme.palette.text.secondary,
			'--palette-text-disabled': theme.palette.text.disabled,
			'--palette-text-hint': theme.palette.text.hint,
			'--palette-text-primary-contrast': theme.palette.getContrastText(theme.palette.text.primary),
			'--palette-text-secondary-contrast': theme.palette.getContrastText(theme.palette.text.secondary),

			'--palette-divider': theme.palette.divider,

			'--palette-grey-50': theme.palette.grey[50],
			'--palette-grey-100': theme.palette.grey[100],
			'--palette-grey-200': theme.palette.grey[200],
			'--palette-grey-300': theme.palette.grey[300],
			'--palette-grey-400': theme.palette.grey[400],
			'--palette-grey-500': theme.palette.grey[500],
			'--palette-grey-600': theme.palette.grey[600],
			'--palette-grey-700': theme.palette.grey[700],
			'--palette-grey-800': theme.palette.grey[800],
			'--palette-grey-900': theme.palette.grey[900],
			'--palette-grey-A100': theme.palette.grey.A100,
			'--palette-grey-A200': theme.palette.grey.A200,
			'--palette-grey-A400': theme.palette.grey.A400,
			'--palette-grey-A700': theme.palette.grey.A700,
		}
	}
})

const N2CSS = () => {
	return ''
}

export default withStyles(cssVariables)(N2CSS)
