import { Dialog, DialogContent, DialogTitle, Grid, Typography, useTheme } from "@material-ui/core";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { APP_API_URL } from "../app/const";
import { devlog, random } from "./n2functions";
import { N2I18N2 } from "./N2I18N2";
import { skipWaitingAndExec } from "./N2NewAppVersion";
import N2PulsatingCircle from "./N2PulsatingCircles";


function N2ContactingServer({ error }) {
	const theme = useTheme()
	const timeoutRef = useRef()
	const [retrying, setRetrying] = useState()
	const tryRef = useRef(0)

	const okAgain = () => {
		skipWaitingAndExec(() => document.location.reload())
	}

	const retry = useCallback(() => {
		timeoutRef.current = setTimeout(() => {
			devlog(tryRef.current)
			// do not use axios: N2App response interceptor!
			fetch(`${APP_API_URL}/pub/heartbeat?try=${tryRef.current}&t=${+new Date()}`,
				{ credentials: 'include' }
			).then(r => r.json()).then(j => {
				devlog(j)
				 if (!!j.maintenance) {
					 if (tryRef.current < 25) {
						 tryRef.current++
						 setRetrying('maint')
						 retry()
					 } else {
						document.location.href = '/maintenance.html'
					 }
				 } else {
					 timeoutRef.current = null
					 setRetrying(null)
					 okAgain()
				 }
			}).catch(e => {
				if (tryRef.current < 3) {
					tryRef.current++
					setRetrying('error')
					retry()
				} else {
					document.location.href = '/maintenance.html'
				}
			})
		}, random(5_000, 10_000))
	}, [])

	useEffect(() => {
		if (!!error) {
			if (timeoutRef.current) clearTimeout(timeoutRef.current)
			tryRef.current = 1
			setRetrying(error)
			retry()
		} else {
			setRetrying(null)
			if (timeoutRef.current) okAgain()
		}
		return () => {
			if (timeoutRef.current) clearTimeout(timeoutRef.current)
		}
	}, [error, retry])

	return <Dialog open={Boolean(retrying)}>
		<DialogTitle>
			<Grid container direction="row" alignItems="center" spacing={2}>
				<Grid item><N2PulsatingCircle stroke={retrying === 'maint' ? theme.palette.primary.main : theme.palette.warning.main}/></Grid>
				<Grid item><N2I18N2 id="client.contactingServer@title" def="Please Wait" /></Grid>
			</Grid>
		</DialogTitle>
		<DialogContent>
			<Typography><N2I18N2 id={`client.contactingServer@${retrying}`}
				def={retrying === 'maint'
					? 'We are performing important maintenance but will be back soon...'
					: 'Network Error, trying to contact the server...'} /></Typography>
		</DialogContent>
	</Dialog>
}

N2ContactingServer.propTypes = {
	error: PropTypes.oneOf(['maint', 'error']),
	config: PropTypes.object
}

export default N2ContactingServer

