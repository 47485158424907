import { CircularProgress, Grid } from '@material-ui/core'
import React from 'react'
import { N2I18N2 } from './N2I18N2'


export const N2Busy = ({ label, children }) => {
	const l = label || children || <N2I18N2 id="common.busy@wait" def="Please wait..." />
	return <div style={{ margin: 32 }}>
		<Grid container direction="row" alignItems="center" spacing={2}>
			<Grid item><CircularProgress /></Grid>
			<Grid item>{l}</Grid>
		</Grid>
	</div>
}
