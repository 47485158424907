import React from 'react';
import './N2View.scss';


export const N2View = ({ className, children }) => {
	return <div className={`N2View ${className}`}>{children}</div>
}


export const N2Toolbar = ({ left, ifBurger, children }) => {
	return <div className={`N2View__toolbar ${ifBurger ? 'N2View__toolbar--ifBurger' : 'N2View__toolbar--regular'}`}>
		<div className="N2View__toolbar__left">{left}</div>
		<div className="N2View__toolbar__right">{children}</div>
	</div>
}

export const N2Content = ({ className, children }) => {
	return <div className={`N2View__content ${className}`}>{children}</div>
}

