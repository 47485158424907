import { Box, Grid, LinearProgress, Paper } from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import './N2Dropzone.css';
import { N2I18N2 } from "./N2I18N2";


const N2Dropzone = ({ url, ...config }) => {
	const [progress, setProgress] = useState()

	const deleteEntry = useCallback(name => {
		setProgress(prev => {
			const p = {...prev}
			delete p[name]
			return p
		})
	}, [])

	const drop = useCallback(async files => {
		files.forEach(file => {
			setProgress(prev => ({
				...prev,
				[file.name]: {pct: 0}
			}))
		})
		if (config.onDrop) await Promise.resolve(config.onDrop(files))
		const paramName = config.paramName || 'file'
		files.forEach(async file => {
			const fd = new FormData()
			if (config.params != null) {
				let o = null
				if (typeof config.params === 'function') {
					o = await Promise.resolve(config.params())
				} else if (typeof config.params === 'object') {
					o = config.params
				}
				if (o) Object.keys(o).forEach(k => fd.set(k, o[k]))
			}
			fd.append(paramName, file)
			axios.post(url, fd, {
				onUploadProgress: pe => {
					setProgress(prev => ({
						...prev,
						[file.name]: {pct: pe.loaded/pe.total*100}
					}))
				}
			}).then(r => {
				setProgress(prev => ({
					...prev,
					[file.name]: {pct: 100, success: `${r.status} ${r.statusText}`}
				}))
				setTimeout(() => {
					deleteEntry(file.name)
				}, 3000)
				if (config.onUploaded) Promise.resolve(config.onUploaded(r))
			}).catch(error => {
				setProgress(prev => ({
					...prev,
					[file.name]: {pct: 0, error}
				}))
				setTimeout(() => {
					deleteEntry(file.name)
				}, 7000)
				if (config.onError) Promise.resolve(config.onError(error))
			})
		})
	}, [config, deleteEntry, url])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		...config,
		onDrop: drop
	})
	return <Paper {...getRootProps()} className="N2Dropzone">
		<input {...getInputProps()} />
		<div>
			{isDragActive ? (
				<N2I18N2 id="client.N2Dropzone@dragging" def="Release to drop the files here" />
			) : (
				<N2I18N2 id="client.N2Dropzone@ready" def="Drag'n'drop file(s) here, or click to select file(s)" />
			)}
		</div>
		{progress && <div>
			{Object.entries(progress).map(([k,v]) =>
				<Grid container key={k} direction="row" alignItems="center" spacing={1}>
					<Grid item xs>{k}</Grid>
					<Grid item xs>
						{!v.error && !v.success && <LinearProgress variant="determinate" value={v.pct} />}
						{v.error && <Box color="error.main">{v.error.response?.data?.message || v.error.message || JSON.stringify(v.error)}</Box>}
						{v.success && <Box color="success.main">{v.success}</Box>}
					</Grid>
				</Grid>)}
		</div>}
	</Paper>
}

export default N2Dropzone
