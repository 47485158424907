import * as d3 from 'd3';
import React, { useEffect, useMemo, useRef } from 'react';



// based on https://observablehq.com/@d3/force-directed-graph

export default function ForceGraph({ data, config }) {
	const nodesMemo = useMemo(() => data.nodes.map(n => ({ ...n })), [data.nodes])
	const linksMemo = useMemo(() => data.links.map(l => ({ ...l })), [data.links])
	const svgRef = useRef()

	useEffect(() => {
		console.log('ForceGraph useEffect')

		const forceNode = d3.forceManyBody()
		const forceLink = d3.forceLink(linksMemo).id(n => n.id)
		const forceCollide = d3.forceCollide(10)
		if (typeof config.nodeStrength === 'function') forceNode.strength(config.nodeStrength)
		if (typeof config.linkStrength === 'function') forceLink.strength(config.linkStrength)
		if (typeof config.linkDistance === 'function') forceLink.distance(config.linkDistance)
		if (typeof config.collideRadius === 'function') forceCollide.radius(config.collideRadius)

		const simulation = d3.forceSimulation(nodesMemo)
			.force("link", forceLink)
			.force("charge", forceNode)
			.force("collide", forceCollide)
			.force("center", d3.forceCenter())
			.on("tick", ticked)
			.alphaMin(0.01)
		if (typeof config.invalidation === 'function') config.invalidation.then(() => simulation.stop());

		const zoom = d3.zoom().on('zoom', e => {
			d3.selectAll('.ForceGraphCanvas').attr('transform', e.transform)
		})

		const svg = d3.select(svgRef.current)
		svg.selectAll("*").remove()

		const canvas = svg.append("g")
			.attr("class", "ForceGraphCanvas")

		svg.call(zoom).call(zoom.transform, d3.zoomIdentity.translate(config.width / 2, config.height / 2))

		const link = canvas.append("g")
			.attr("class", "links")
			.selectAll("line")
			.data(linksMemo)
			.join("line")
			.attr("stroke-width", l => config.linkStrokeWidth(l))

		const node = canvas.append("g")
			.attr("class", "nodes")
			.selectAll("g")
			.data(nodesMemo)
			.join("g")
			.call(drag(simulation))
		const circles = node.append("circle")
			.attr("class", n => config.nodeClassname(n))
			.attr("r", 15)
			.attr("fill", n => config.nodeFill(n))
			.attr("transform", n => `scale(${config.nodeScale(n)})`)
		const labels = node.append("text")
			.attr("font-size", n => config.nodeLabelFontSize(n))
			.attr("transform", n => `scale(${config.nodeScale(n)})`)
			.text(n => config.nodeLabel(n))
		if (config.nodeOnClick != null) node.on('click', config.nodeOnClick)

		function ticked() {
			link
				.attr("x1", d => d.source.x)
				.attr("y1", d => d.source.y)
				.attr("x2", d => d.target.x)
				.attr("y2", d => d.target.y);
			node
				.attr("transform", d => `translate(${d.x}, ${d.y})`)
		}
	}, [config, linksMemo, nodesMemo])

	return <svg ref={svgRef} width={config.width} height={config.height}></svg>
}


function drag(simulation) {
	function dragstarted(event) {
		if (!event.active) simulation.alphaTarget(0.3).restart();
		event.subject.fx = event.subject.x;
		event.subject.fy = event.subject.y;
	}

	function dragged(event) {
		event.subject.fx = event.x;
		event.subject.fy = event.y;
	}

	function dragended(event) {
		if (!event.active) simulation.alphaTarget(0);
		// event.subject.fx = null;
		// event.subject.fy = null;
	}

	return d3.drag()
		.on("start", dragstarted)
		.on("drag", dragged)
		.on("end", dragended);
}

