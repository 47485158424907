import { CssBaseline, IconButton, ThemeProvider } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import axios from 'axios';
import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { Router, View } from 'react-navi';
import HelmetProvider from 'react-navi-helmet-async';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { APP_API_URL, INTL_FORMATS } from '../app/const';
import navigation from '../app/nav';
import { useAppTheme } from '../app/styles';
import { AppNav, toggleDrawer } from '../components/AppNav';
import './N2App.scss';
import { N2AuthDialogProvider } from './N2AuthDialog2';
import { N2BroadcastFeed } from './N2BroadcastFeed';
import { N2Busy } from './N2Busy';
import N2CSS from './N2CSS';
import { N2ConfirmProvider } from './N2Confirm2';
import N2ContactingServer from './N2ContactingServer';
import { N2EventFeed, n2objectFeed } from './N2EventFeed';
import { N2I18N2Provider } from './N2I18N2';
import N2NewAppVersion, { skipWaitingAndExec, skipWaitingAndReload, updateServiceWorker } from './N2NewAppVersion';
import N2Offline from './N2Offline';
import { N2Snackbar, showError } from './N2Snackbar';
import { devlog, getStoredLocale, random } from './n2functions';
import { useOnlineStatus, useUpdateEffect } from './n2hooks';


const queryClient = new QueryClient()
export const AppProvider = React.createContext()


function logout() {
	axios.post(`${APP_API_URL}/pub/rest/auth/logout`, {}, { showError: false })
	queryClient.clear()
	skipWaitingAndExec(() => document.location.href = '/login')
}


export default function N2App() {
	const theme = useAppTheme()
	const online = useOnlineStatus()
	const [auth, setAuth] = useState()
	const [locale, setLocale] = useState(getStoredLocale())
	const [axiosError, setAxiosError] = useState()

	const isInRole = useCallback((...roles) => {
		return auth && auth.roles.filter(r => roles.includes(r)).length > 0
	}, [auth])

	const requireRole = useCallback((roles, onUnauthorized) => {
		if (typeof roles === 'function') roles = roles()
		if (typeof roles === 'string') roles = [roles]
		if (!isInRole(...roles)) {
			if (onUnauthorized) {
				onUnauthorized()
			} else {
				showError('Missing required role')
				logout()
			}
		}
	}, [isInRole])

	const impersonateLogout = useCallback(() => {
		queryClient.clear()
		const url = `/admin/users/${auth.id}`
		axios.post(`${APP_API_URL}/rest/user/impersonate/logout`, {}).then(r => {
			setAuth(r.data.user)
			localStorage.setItem('auth', JSON.stringify(r.data.user))
			navigation.navigate(url)
		}).catch(e => {
			logout()
		})
	}, [auth])

	// set up axios
	useEffect(() => {
		axios.defaults.withCredentials = true
		const interceptor = axios.interceptors.response.use(response => {
			if (!!response.data?.maintenance) {
				setAxiosError('maint')
				return Promise.reject(response)
			} else {
				return Promise.resolve(response)
			}
		}, error => {
			devlog('error', error)
			devlog('request', error.request)
			devlog('response', error.response)
			if ([0, 429, 502, 503, 504].includes(error.request.status)) {
				setAxiosError('error')
				return Promise.reject(error)
			} else {
				if (error.config.showError == null || error.config.showError) showError(error)
				if (error.response?.status === 403) logout()
				return Promise.reject(error)
			}
		})
		return () => {
			axios.interceptors.response.eject(interceptor)
		}
	}, [])

	// auth: open tab / refresh page
	useEffect(() => {
		const localAuth = JSON.parse(localStorage.getItem('auth') || '{}')
		if (localAuth.username) {
			setAuth(localAuth)
		} else {
			localStorage.removeItem('auth')
			if (!navigation.getCurrentValue().url.pathname?.match('^/(login|r/).*')) logout()
		}
	}, [])

	// auth: localStorage auth change
	useEffect(() => {
		function onStorage(event) {
			if (event.key === 'auth') {
				devlog('storage event auth', event)
				if (event.newValue != null) {
					queryClient.invalidateQueries(['user', auth.id]).then(() => {
						if (navigation.getCurrentValue().url.pathname?.startsWith('/login')) navigation.navigate('/dashboard')
					})
				} else {
					logout()
				}
			}
		}
		window.addEventListener('storage', onStorage)
		return () => window.removeEventListener('storage', onStorage)
	}, [])

	// auth: objectFeed User change
	useEffect(() => {
		if (!auth) return
		const ofs = n2objectFeed.subscribe(msg => {
			devlog('App objectFeed', msg)
			if (msg.type === 'User' && msg.id === auth?.id) {
				queryClient.invalidateQueries(['user', auth.id])
			}
		})
		return () => ofs.unsubscribe()
	}, [auth])

	// heartbeat
	useEffect(() => {
		const i = setInterval(() => {
			axios.get(`${APP_API_URL}/pub/heartbeat?t=${+new Date()}`).then(r => {
				if (r.data.action === 'reload') {
					skipWaitingAndReload()
				} else if (r.data.action === 'logout') {
					logout()
				} else if (r.data.action === 'close') {
					document.location.href = 'about:blank'
				}
			})
		}, random(1_000*60 * 9, 1_000*60 * 11))
		return () => clearInterval(i)
	}, [])

	// new app version
	useEffect(() => {
		navigation.subscribe(route => {
			updateServiceWorker()
		})

		const i = setInterval(updateServiceWorker, 1_000*60 * 2)
		return () => clearInterval(i)
	}, [])

	useUpdateEffect(() => {
		if (online) window.location.reload()
	}, [online])

	return (
		<HelmetProvider>
		<ThemeProvider theme={theme}>

		<N2CSS />
		<QueryClientProvider client={queryClient}>
		<AppProvider.Provider value={{ auth, setAuth, logout, isInRole, requireRole, setLocale }}>
			<N2I18N2Provider locale={locale} formats={INTL_FORMATS}>

			<N2ConfirmProvider>
			<N2AuthDialogProvider>

			<div className="N2App">
				<CssBaseline />

				<N2Offline />
				<N2ContactingServer error={axiosError} />

				<AppNav impersonateLogout={impersonateLogout} />

				<main>
					<IconButton onClick={toggleDrawer} className="mainMenuButton"><Icon>menu</Icon></IconButton>
					<Router navigation={navigation}>
						<Suspense fallback={<N2Busy />}>
							<View />
						</Suspense>
					</Router>
				</main>
			</div>

			</N2AuthDialogProvider>
			</N2ConfirmProvider>

			<N2Snackbar />
			<N2NewAppVersion />

			</N2I18N2Provider>
		</AppProvider.Provider>
		</QueryClientProvider>

		{auth && <>
			<N2EventFeed />
			<N2BroadcastFeed />
		</>}

		<N2Snackbar />

		</ThemeProvider>
		</HelmetProvider>
	)
}


export function useUser() {
	const { auth } = useContext(AppProvider)

	return useQuery(['user', auth.id], async () => {
		return (await axios.get(`${APP_API_URL}/rest/user`)).data
	}, { enabled: auth != null, staleTime: 5*60*1_000 })
}

