import { createBrowserNavigation, mount, redirect, route } from "navi";
import React from 'react';
import { LoginForm, OptIn, ResetPassword, Signup } from "../components/LoginForm";
import { Team } from '../components/Team';
import { N2AdminCMSPage, N2AdminCMSPages } from '../n2shared/N2CMS';
import { N2AdminI18N2, N2AdminI18N2s } from "../n2shared/N2I18N2";

const Account = React.lazy(() => import('../components/Account'));
const Dashboard = React.lazy(() => import('../components/Dashboard'));
const Finance = React.lazy(() => import('../components/Finance'));
const Customers = React.lazy(() => import('../components/Customers'));
const Customer = React.lazy(() => import('../components/Customer'));
const TeamGraph = React.lazy(() => import('../components/TeamGraph'));
const Training = React.lazy(() => import('../components/Training'));
const Infos = React.lazy(() => import('../components/Infos'));
const Support = React.lazy(() => import('../components/Support'));
const AdminDash = React.lazy(() => import('../components/AdminDash'));
const AdminPayouts = React.lazy(() => import('../components/AdminPayouts'));
const AdminUsers = React.lazy(() => import('../components/AdminUsers'));
const AdminUser = React.lazy(() => import('../components/AdminUser'));
const N2AdminNodeLog = React.lazy(() => import('../n2shared/N2AdminNodeLog'));


const routes = mount({
	'/': redirect('./dashboard'),
	'/login': mount({
		'/': route({
			title: 'Login',
			view: <LoginForm />
		}),
		'/up/:u': route(req => ({
			title: 'Login',
			view: <LoginForm u={req.params.u} />
		})),
		'/up/:u/:p': route(req => ({
			title: 'Login',
			view: <LoginForm u={req.params.u} p={req.params.p} />
		})),
		'/pw/:token': route(req => ({
			title: 'Reset Password',
			view: <ResetPassword token={req.params.token} />
		})),
		'/signup/:aff': route(req => ({
			title: 'Signup',
			view: <Signup aff={req.params.aff} />
		})),
		'/signup/:aff/:uuid': route(req => ({
			title: 'Signup',
			view: <Signup aff={req.params.aff} uuid={req.params.uuid} />
		})),
		'/optin/:token': route(req => ({
			title: 'OptIn',
			view: <OptIn token={req.params.token} />
		})),
	}),
	'/dashboard': route({
		title: 'Dashboard',
		view: <Dashboard />
	}),
	'/team': mount({
		'/': route({
			title: 'Team',
			view: <Team />
		}),
		'/graph': route({
			title: 'Team Graph',
			view: <TeamGraph />
		}),
	}),
	'/customers': mount({
		'/': route({
			title: 'Customers',
			view: <Customers />
		}),
		'/:uuid': route(req => ({
			title: 'Customer',
			view: <Customer uuid={req.params.uuid} />
		})),
	}),
	'/finance/:tab': route(req => ({
		title: 'Finance',
		view: <Finance startTab={req.params.tab} />
	})),
	'/training': mount({
		'/': route({
			title: 'Training',
			view: <Training />
		}),
		'/:uid': route(req => ({
			title: 'Training',
			view: <Training pageUid={req.params.uid} />
		})),
	}),
	'/infos': mount({
		'/': route({
			title: 'Infos',
			view: <Infos />
		}),
		'/:uid': route(req => ({
			title: 'Info',
			view: <Infos pageUid={req.params.uid} />
		})),
	}),
	'/account': route({
		title: 'Account',
		view: <Account />
	}),
	'/account/:tab': route(req => ({
		title: 'Account',
		view: <Account startTab={req.params.tab} />
	})),
	'/support': route({
		title: 'Support',
		view: <Support />
	}),
	'/admin': mount({
		'/dash': route({
			title: 'Admin Dash',
			view: <AdminDash />
		}),
		'/users': mount({
			'/': route({
				title: 'Admin Users',
				view: <AdminUsers />
			}),
			'/q/:query': route(req => ({
				title: 'Admin Users',
				view: <AdminUsers query={req.params.query} />
			})),
			'/:userId': route(req => ({
				title: 'Admin User',
				view: <AdminUser userId={req.params.userId} />
			})),
		}),
		'/payouts': route({
			title: 'Payouts',
			view: <AdminPayouts />
		}),
		'/i18n2': mount({
			'/': route({
				title: 'I18N2s',
				view: <N2AdminI18N2s />
			}),
			'/:valueId': route(req => ({
				title: 'I18N2',
				view: <N2AdminI18N2 valueId={+req.params.valueId} />
			})),
		}),
		'/cms': mount({
			'/': route({
				title: 'CMS Pages',
				view: <N2AdminCMSPages />
			}),
			'/:pageId': route(req => ({
				title: 'CMS Page',
				view: <N2AdminCMSPage pageId={req.params.pageId} />
			})),
		}),
		'/nodelog': mount({
			'/': route({
				title: 'NodeLog',
				view: <N2AdminNodeLog />
			}),
		}),
	}),
})

const navigation = createBrowserNavigation({ routes })

export default navigation