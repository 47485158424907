import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import React, { createContext, useContext, useRef, useState } from 'react';
import { devlog } from './n2functions';
import { N2I18N2, useN2I18N2 } from './N2I18N2';
import { showSnackbar } from './N2Snackbar';


const ConfirmContext = createContext(Promise.reject)


export const N2ConfirmProvider = ({ children }) => {
	const n2i18n2 = useN2I18N2()
	const [state, setState] = useState()
	const code = useRef()
	const promiseRef = useRef()

	const showConfirm = config => {
		devlog(config)
		const s = { ...config }
		s.title = s.title || n2i18n2.formatMessage('client.confirmDialog@title', 'Confirm')
		s.text = s.text || n2i18n2.formatMessage('client.confirmDialog@areYouSure', 'Are you sure?')
		s.showNo = (typeof s.showNo === 'boolean') ? s.showNo : true
		s.no = s.no || n2i18n2.formatMessage('client.common@no', 'No')
		s.yes = s.yes || n2i18n2.formatMessage('client.common@yes', 'Yes')
		s.code = Math.round(Math.random() * 899 + 100)
		setState(s)
		devlog(s)

		return new Promise((resolve, reject) => {
			promiseRef.current = { resolve, reject }
		})
	}

	const handleNo = () => {
		if (state.catchOnCancel && promiseRef.current) promiseRef.current.reject()
		setState(null)
	}

	const handleYes = () => {
		if (state.requireCode) {
			const c = +code.current.value
			if (c !== state.code) {
				showSnackbar(n2i18n2.formatMessage('client.confirmDialog@invalidCode', 'Invalid code'))
				return
			}
		}
		if (promiseRef.current) promiseRef.current.resolve()
		setState(null)
	}

	return <>
		<ConfirmContext.Provider value={showConfirm} children={children} />

		<Dialog open={Boolean(state)}>
			<DialogTitle>{state?.title}</DialogTitle>
			<DialogContent>
				<Typography>{state?.text}</Typography>
				{state?.requireCode && <Grid container direction="row" alignItems="center" spacing={1}>
					<Grid item><N2I18N2 id="client.confirmDialog@enterCode" def="Enter {code} to confirm:" values={state}/></Grid>
					<Grid item><TextField type="number" inputRef={code} label="Code" autoFocus margin="dense" fullWidth autoComplete="off"/></Grid>
				</Grid>}
			</DialogContent>
			<DialogActions>
				{state?.showNo ? <Button onClick={handleNo}>{state.no}</Button> : undefined}
				<Button onClick={handleYes}>{state?.yes}</Button>
			</DialogActions>
		</Dialog>
	</>
}


export const useN2Confirm = () => useContext(ConfirmContext)
