import React from 'react'
import { APP_API_URL } from '../app/const'


const N2Thumbnailer = ({ src, square, size, sizes, ...attrs }) => {
	const url = `${APP_API_URL}/pub/thumbnailer?`
	const params = { src, square: !!square }
	if (Array.isArray(sizes)) {
		const webpSources = [], jpgSources = []
		let fallback = url + new URLSearchParams({ ...params, size, format:'jpg' })
		sizes.forEach(entry => {
			let jpgs = '', webps = ''
			for (const density in entry.densities) {
				const size = entry.densities[density]
				jpgs += url + new URLSearchParams({ ...params, size, format:'jpg' }) + ' ' + density + ', '
				webps += url + new URLSearchParams({ ...params, size, format:'webp' }) + ' ' + density + ', '
			}
			webpSources.push({ media: entry.media, srcset: webps, type: 'image/webp' })
			jpgSources.push({ media: entry.media, srcset: jpgs, type: 'image/jpeg' })
		})
		return <picture className={attrs?.pictureClass || ''}>
			{webpSources.map((s, i) => <source key={i} media={s.media} srcSet={s.srcset} type={s.type} />)}
			{jpgSources.map((s, i) => <source key={i} media={s.media} srcSet={s.srcset} type={s.type} />)}
			<img src={fallback} className={attrs?.imgClass || ''} loading="lazy" alt={attrs?.alt || ''} crossOrigin="use-credentials" />
		</picture>
	} else if (typeof size === 'number') {
		const jpg = url + new URLSearchParams({ ...params, size, format:'jpg' })
		const webp = url + new URLSearchParams({ ...params, size, format:'webp' })
		return <picture className={attrs?.pictureClass || ''}>
			<source srcSet={webp} type="image/webp" />
			<img src={jpg} className={attrs?.imgClass || ''} loading="lazy" alt={attrs?.alt || ''} crossOrigin="use-credentials" />
		</picture>
	}
	return null
}

export default N2Thumbnailer
