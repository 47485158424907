import { Avatar, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, Icon, InputLabel, Link, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isMobileSafari } from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { useNavigation } from 'react-navi';
import { APP_API_URL, useCountries } from '../app/const';
import { AppProvider } from '../n2shared/N2App';
import { useN2AuthDialog } from '../n2shared/N2AuthDialog2';
import { N2I18N2, useN2I18N2 } from '../n2shared/N2I18N2';
import { showError, useN2Snackbar } from '../n2shared/N2Snackbar';
import { devlog, inputError } from '../n2shared/n2functions';
import './LoginForm.scss';


export const LoginForm = ({ u, p }) => {
	const n2i18n2 = useN2I18N2()
	const n2snackbar = useN2Snackbar()
	const n2authenticate = useN2AuthDialog()
	const { setAuth, setLocale } = useContext(AppProvider)
	const { register, handleSubmit, getValues, errors, formState } = useForm({
		defaultValues: { username: u, password: p }
	})
	const navigation = useNavigation()
	const [noOptIn, setNoOptIn] = useState()
	// const recaptchaRef = useRef()

	const onSubmit = useCallback(async data => {
		// if (process.env.NODE_ENV === 'production' && !data.reCaptchaToken) {
		// 	data.reCaptchaToken = await recaptchaRef.current.executeAsync()
		// }
		axios.post(`${APP_API_URL}/pub/rest/auth/login`, data).then(r => {
			if (r.data.authenticated) {
				localStorage.setItem('auth', JSON.stringify(r.data.user))
				if (r.data.user.languageIso) setLocale(r.data.user.languageIso)
				setAuth(r.data.user)
				navigation.navigate('/dashboard')
			} else if (r.data.require2FA) {
				n2authenticate({ authType: 'TOTP' }).then(auth => {
					onSubmit({ ...data, auth})
				})
			} else if (r.data.error === 'noOptIn') {
				setNoOptIn(r.data)
			}
		}, error => {
			// recaptchaRef.current.reset()
		})
	}, [navigation, setAuth, setLocale, n2authenticate])

	const forgotPassword = useCallback(d => {
		if (d.username.trim() === '') {
			n2snackbar({
				id: 'client.login@forgotPasswordUsernameRequired',
				def: 'Enter your username and try again'
			})
			return
		}
		axios.post(`${APP_API_URL}/pub/rest/auth/forgotPassword`, d).then(r => {
			if (r.data.tokenSet) {
				n2snackbar({
					id: 'client.login@checkMail',
					def: 'Check {email}',
					values: { email: r.data.anonEmail }
				})
			} else {
				n2snackbar({
					id: 'client.login@forgotPasswordError',
					def: `That didn't work. Is {username} a valid username?`,
					values: d
				})
			}
		})
	}, [n2snackbar])

	const resendOptIn = useCallback(e => {
		axios.get(`${APP_API_URL}/pub/rest/auth/signup/resend/${noOptIn.id}`).then(r => {
			if (r.data.resent) {
				n2snackbar({
					id: 'client.login@checkMail',
					def: 'Check {email}',
					values: {email: r.data.user.email}
				})
				setNoOptIn(null)
			}
		})
	}, [n2snackbar, noOptIn])

	useEffect(() => {
		if (u && u.length>0 && p && p.length>0) onSubmit({ username: u, password: p })
	}, [formState, onSubmit, p, u])

	useEffect(() => {
		localStorage.removeItem('auth')
		setAuth(null)
	}, [setAuth])

	const required = n2i18n2.formatMessage('client.common@inputRequired')
	return <Container maxWidth="sm" className="LoginForm">
		<div className="n2installPWA" style={{ display: 'none' }}>
			<Alert variant="filled" severity="info" action={<Button onClick={e => window.n2installPWA(e)} variant="contained" color="primary" startIcon={<Icon>install_mobile</Icon>}><N2I18N2 id="client.login.installPWA@button" def="Install"/></Button>}>
				<N2I18N2 id="client.login.installPWA@text" def="Add the TCN APP to your Home Screen"/>
			</Alert>
		</div>
		{isMobileSafari && !window.n2standalone() && <Alert variant="filled" severity="info" className="mobileSafari">
			<N2I18N2 id="client.login.installPWA@safariAddToHomeScreen" def="Tap {share} then 'Add to Home Screen'"
				values={{ share: '<img src="/assets/safari_share.svg" alt="Share" loading="lazy" />' }} html />
		</Alert>}

		<img src="/logo.svg" className="logo" alt="logo" loading="lazy" />
		<Paper>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
					<Grid item><Avatar><Icon>lock</Icon></Avatar></Grid>
					<Grid item xs={12} sm={4}>
						<TextField name="username" inputRef={register({required})}
							label={<N2I18N2 id="domain.User.username@label" def="Username"/>}
							{...inputError(errors,'username')} autoComplete="off" />
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField name="password" type="password" inputRef={register({required})}
							label={<N2I18N2 id="domain.User.password@label" def="Password"/>}
							{...inputError(errors,'password')} autoComplete="off" />
					</Grid>
					<Grid item>
						<Button type="submit" variant="contained" color="primary">
							<N2I18N2 id="client.login@submit" def="Log In"/>
						</Button>
					</Grid>
					<Button onClick={e => forgotPassword(getValues())} variant="outlined">
						<N2I18N2 id="client.login@forgotPassword" def="Forgot Password"/>
					</Button>
					{noOptIn && <div style={{ margin: "16px 0" }}>
						<Typography><N2I18N2 id="client.login@noOptIn" def="You need to confirm your e-mail address {email} first!" values={noOptIn}/></Typography>
						<Button onClick={resendOptIn} variant="contained" color="primary"><N2I18N2 id="client.login@resendOptIn" def="Re-Send Mail" /></Button>
					</div>}
				</Grid>
			</form>
		</Paper>
		<Alert severity="info" style={{ marginTop: 32 }}>
			<N2I18N2 id="client.login@securityHint" def="Security advice: Open a private browser / incognito mode if you're not using your own personal device and always log out when you are done!"/>
		</Alert>
		{/* <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SECRET} size="invisible" /> */}
	</Container>
}


export const ResetPassword = ({ token }) => {
	const navigation = useNavigation()
	const [notified, setNotified] = useState()

	useEffect(() => {
		if (!token) return
		setTimeout(() => {
			const d = { passwordChangeToken: token }
			axios.post(`${APP_API_URL}/pub/rest/auth/resetPassword`, d).then(r => {
				if (r.data.passwordReset) {
					setNotified(r.data.notified)
				}
			})
		}, 1000)
	}, [token])

	return <Container maxWidth="sm" className="LoginForm">
		<img src="/logo.svg" className="logo" alt="logo" loading="lazy" />
		<Paper>
			{notified && <div>
				<Typography>
					<N2I18N2 id="client.login@passwordReset" def="Password reset, check {notified}" values={{ notified}}/>
				</Typography>
			</div>}
			<Button onClick={e => navigation.navigate(`/login`)} variant="contained" color="primary">Log In</Button>
		</Paper>
	</Container>
}


export const Signup = ({ aff, uuid }) => {
	const navigation = useNavigation()
	const n2i18n2 = useN2I18N2()
	const n2snackbar = useN2Snackbar()
	const countries = useCountries()
	const { register, handleSubmit, errors, control, setError, reset } = useForm()
	const recaptchaRef = useRef()

	useEffect(() => {
		if (uuid) {
			axios.get(`${APP_API_URL}/pub/rest/auth/signup/customer/${aff}/${uuid}`).then(r => {
				reset(r.data)
			})
		}
	}, [aff, reset, uuid])

	const onSubmit = useCallback(async data => {
		if (data.password !== data.password2) {
			showError(n2i18n2.formatMessage('client.signup@passwordsNotSame', 'Passwörter stimmen nicht überein'))
			setError('password', {
				type: 'required',
				message: n2i18n2.formatMessage('client.common@inputRequired')
			})
			setError('password2', {
				type: 'required',
				message: n2i18n2.formatMessage('client.common@inputRequired')
			})
			return
		}
		data.reCaptchaToken = await recaptchaRef.current.executeAsync()
		data.aff = aff
		data.uuid = uuid
		data.languageIso = n2i18n2.locale
		axios.post(`${APP_API_URL}/pub/rest/auth/signup`, data).then(r => {
			if (r.data.signedUp) {
				n2snackbar({
					id: 'client.signup@signedUp',
					def: 'Check {email} and confirm registration',
					values: r.data.user
				})
				window.fbq('track', 'CompleteRegistration')
				setTimeout(() => {
					navigation.navigate('/login')
				}, 1000)
			}
		}).catch(error => { recaptchaRef.current.reset() })
	}, [aff, uuid, n2i18n2, setError, n2snackbar, navigation])

	const href = t => (<Link href={n2i18n2.formatMessage('client.signup@checkTacHref')} target="_blank">{t}</Link>)
	const required = n2i18n2.formatMessage('client.common@inputRequired')
	const email = {value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/, message: n2i18n2.formatMessage('client.common@inputEmail')}
	const tel = {value: /^(\+|00)([0-9]){5,}$/, message: n2i18n2.formatMessage('client.common@inputTel')}
	const username = {value: /^[\w-.]{5,}$/, message: n2i18n2.formatMessage('client.common@inputUsername')}
	const password = {value: 6, message: n2i18n2.formatMessage('client.common@inputPassword')}
	// const screenname = {value: /^[a-z][a-z0-9]+$/, message: n2i18n2.formatMessage('client.common@inputScreenname')}
	return <>
		<Container maxWidth="md" className="LoginForm">
			<img src="/logo.svg" className="logo" alt="logo" />
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<Paper className="Signup">
					<Controller as={TextField} name="firstname" control={control} rules={{ required }} defaultValue=""
						label={<N2I18N2 id="domain.User.firstname@label" def="First Name"/>}
						required fullWidth {...inputError(errors,'firstname')} className="Signup_firstname" />
					<Controller as={TextField} name="lastname" control={control} rules={{ required }} defaultValue=""
						label={<N2I18N2 id="domain.User.lastname@label" def="Last Name"/>}
						required fullWidth {...inputError(errors,'lastname')} className="Signup_lastname" />
					<Controller as={TextField} name="email" type="email" control={control} rules={{ required, pattern: email }} defaultValue=""
						label={<N2I18N2 id="domain.User.email@label" def="Email"/>}
						required fullWidth {...inputError(errors,'email')} className="Signup_email" />
					<Controller as={TextField} name="mobile" type="tel" control={control} rules={{ required, pattern: tel }} defaultValue=""
						label={<N2I18N2 id="domain.User.mobile@label" def="Mobile"/>}
						placeholder="+4911122222"
						required fullWidth {...inputError(errors,'mobile')} className="Signup_mobile" />
					<Controller as={TextField} name="street" control={control} rules={{ required }} defaultValue=""
						label={<N2I18N2 id="domain.User.street@label" def="Street"/>}
						required fullWidth {...inputError(errors,'street')} className="Signup_street" />
					<Controller as={TextField} name="zip" control={control} rules={{ required }} defaultValue=""
						label={<N2I18N2 id="domain.User.zip@label" def="ZIP"/>}
						required fullWidth {...inputError(errors,'zip')} className="Signup_zip" />
					<Controller as={TextField} name="city" control={control} rules={{ required }} defaultValue=""
						label={<N2I18N2 id="domain.User.city@label" def="City"/>}
						required fullWidth {...inputError(errors,'city')} className="Signup_city" />
					<FormControl variant="outlined" fullWidth required className="Signup_country">
						<InputLabel><N2I18N2 id="domain.User.countryIso@label" def="Country"/></InputLabel>
						<Controller name="countryIso" defaultValue={''} control={control} rules={{required}} as={
							<Select {...inputError(errors,'countryIso')} labelWidth={60}>
								{Object.entries(countries).map(([k,v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)}
							</Select>
						} />
					</FormControl>
					<TextField name="username" inputRef={register({required, pattern: username})}
						label={<N2I18N2 id="domain.User.username@label" def="Username"/>}
						required fullWidth {...inputError(errors,'username')} className="Signup_username" />
					<TextField name="password" type="password" inputRef={register({required, minLength: password})}
						label={<N2I18N2 id="domain.User.password@label" def="Password"/>}
						required fullWidth {...inputError(errors,'password')} className="Signup_password" />
					<TextField name="password2" type="password" inputRef={register({required, minLength: password})}
						label={<N2I18N2 id="domain.User.password2@label" def="Passwort Wiederholung"/>}
						required fullWidth {...inputError(errors,'password2')} className="Signup_password2" />
					{/* <TextField name="screenname" inputRef={register({ required, pattern: screenname })}
						label={<N2I18N2 id="domain.User.screenname@label" def="ScreenName"/>}
						required fullWidth {...inputError(errors,'screenname')} className="Signup_screenname" />
					<div className="Signup_screennameinfo"><N2I18N2 id="client.signup@screennameInfo" def="Screenname Info" html /></div> */}
					<div className="Signup_age">
						<FormControlLabel control={
							<Checkbox name="age" inputRef={register({required})} required color="default" />
						} label={<N2I18N2 id="client.signup@checkAge" def="Above 18" html />} />
						{errors?.age && <Typography color="error" className="MuiFormHelperText-root Mui-error">{errors.age.message}</Typography>}
					</div>
					<div className="Signup_tac">
						<FormControlLabel control={
							<Checkbox name="tac" inputRef={register({required})} required color="default" />
						} label={<N2I18N2 id="client.signup@checkTac" def="TaC" values={{ href }} html/>} />
						{errors?.tac && <Typography color="error" className="MuiFormHelperText-root Mui-error">{errors.tac.message}</Typography>}
					</div>
					<Button type="submit" variant="contained" color="primary" className="Signup_submit">
						<N2I18N2 id="client.signup@submit" def="Sign Up"/>
					</Button>
					<Grid container direction="row" spacing={2} alignItems="center" justifyContent="center" className="Signup_login">
						<Grid item><Typography><N2I18N2 id="client.signup@alreadyUser" def="Already user?"/></Typography></Grid>
						<Grid item><Button variant="outlined" onClick={e => navigation.navigate('/login')}>Log In</Button></Grid>
					</Grid>
				</Paper>
			</form>
			<ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SECRET} size="invisible" />
		</Container>
	</>
}


export const OptIn = ({ token }) => {
	const navigation = useNavigation()
	const [data, setData] = useState()

	useEffect(() => {
		if (!token) return
		setTimeout(() => {
			const d = { optInToken: token }
			devlog('posting optin', d)
			axios.post(`${APP_API_URL}/pub/rest/auth/signup/optin`, d)
				.then(r => {
					window.fbq('track', 'StartTrial');
					setData(r.data)
				})
				.catch(error => { setData(error); showError(error) })
		}, 1000)
	}, [token])

	return <Container maxWidth="sm" className="LoginForm">
		<img src="/logo.svg" className="logo" alt="logo" loading="lazy" />
		<Paper>
			{data==null && <Typography>Confirming...</Typography>}
			{data?.user?.optIn && <Typography><N2I18N2 id="client.signup@optedIn" def="Confirmed, Log In now"/></Typography>}
			<Button onClick={e => navigation.navigate(`/login`)} variant="contained" color="primary">Log In</Button>
		</Paper>
	</Container>

}
