import Dexie from 'dexie';


const dexie = new Dexie('TCN');
export default dexie

dexie.version(1).stores({
	i18n: 'valueId, lang, modified',
})

