import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import React from 'react';
import { useOnlineStatus } from "./n2hooks";


const N2Offline = () => {
	const online = useOnlineStatus()

	return <Dialog open={!online}>
		<DialogTitle><Icon>offline_bolt</Icon> OFFLINE</DialogTitle>
		<DialogContent>
			<Typography>You need an internet connection to use this app</Typography>
		</DialogContent>
	</Dialog>
}

export default N2Offline

