import moment from 'moment'
import React, { useEffect, useState } from 'react'


const makeExpiry = (when, format) => when ? moment.utc(moment(when).diff(moment())).format(format) : null


const N2Expiry = ({ when, onExpiry, format = 'mm:ss' }) => {
	const [expiry, setExpiry] = useState(makeExpiry(when, format))

	useEffect(() => {
		const i = setInterval(() => {
			const m = moment(when)
			if (m.isBefore()) {
				clearInterval(i)
				if (onExpiry) onExpiry()
			} else {
				setExpiry(makeExpiry(when, format))
			}
		}, 1000)

		return () => clearInterval(i)
	}, [format, onExpiry, when])

	return <span>{expiry}</span>
}


export default N2Expiry

