import axios from "axios"
import { useCallback, useEffect, useRef, useState } from "react"
import EASING_FUNCTIONS from "./n2easing"


export const useCounter = (initialValue = 0) => {
	const [count, setCount] = useState(initialValue)

	const next = () => setCount(prev => prev++)
	const reset = () => setCount(initialValue)

	return [count, next, reset]
}


export const useSwitch = (defaultOn = false) => {
	const [on, setOn] = useState(defaultOn)

	const toggle = useCallback(() => {
		setOn(prev => !prev)
	}, [])

	return [on, toggle, setOn]
}


export const usePrevious = (value) => {
	const ref = useRef()

	useEffect(() => {
		ref.current = value
	}, [value])

	return ref.current
}


export const useTimeout = (callback, delay) => {
	const callbackRef = useRef(callback)
	const timeoutRef = useRef(0)

	useEffect(() => {
		callbackRef.current = callback
	}, [callback])

	const set = useCallback(() => {
		timeoutRef.current = setTimeout(callbackRef.current, delay)
	}, [delay])

	const clear = useCallback(() => {
		timeoutRef.current && clearTimeout(timeoutRef.current)
	}, [])

	useEffect(() => {
		set()
		return clear
	}, [set, clear])

	const reset = useCallback(() => {
		clear()
		set()
	}, [clear, set])

	return { reset, clear }
}


export const useUpdateEffect = (callback, deps = []) => {
	const firstRenderRef = useRef(true)

	useEffect(() => {
		if (firstRenderRef.current) {
			firstRenderRef.current = false
			return
		}
		return callback()
	}, deps)
}


export const useEffectOnce = (callback) => {
	useEffect(callback, [])
}


export const useDebouncedCallback = (callback, delay, deps = []) => {
	const { reset, clear } = useTimeout(callback, delay)
	const d = [reset]
	if (deps) d.push(deps)
	useEffect(reset, d)
	useEffect(clear, [])
}


export const useDebouncedValue = (value, delay) => {
	const [v, setV] = useState(value)
	useDebouncedCallback(() => setV(value), delay, [value, delay])
	return v
}


export const useLocalStorage = (key, defaultValue) => {
	return _useStorage(key, defaultValue, window.localStorage)
}

export const useSessionStorage = (key, defaultValue) => {
	return _useStorage(key, defaultValue, window.sessionStorage)
}

const _useStorage = (key, defaultValue, storage) => {
	const [value, setValue] = useState(() => {
		const v = storage.getItem(key)
		if (v != null) return JSON.parse(v)
		return (typeof defaultValue === 'function') ? defaultValue() : defaultValue
	})

	useEffect(() => {
		if (value === undefined) return storage.removeItem(key)
		storage.setItem(key, JSON.stringify(value))
	}, [key, value, storage])

	const remove = useCallback(() => {
		setValue(undefined)
	}, [])

	return [value, setValue, remove]
}


/**
 * executes promise immediately and when deps change or when calling load
 * @returns [value, loading, error, load]
 */
export const useAsync = (promise, deps = []) => {
	return _useAsync(promise, deps, useEffect)
}

/**
 * executes promise only when deps change or when calling load
 * @returns [value, loading, error, load]
 */
 export const useUpdateAsync = (promise, deps = []) => {
	return _useAsync(promise, deps, useUpdateEffect)
}

const _useAsync = (promise, deps = [], effect) => {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState()
	const [value, setValue] = useState()

	const load = useCallback(() => {
		setLoading(true)
		setError(undefined)
		setValue(undefined)
		return promise()
			.then(setValue, setError)
			.finally(() => setLoading(false))
	}, deps)

	effect(() => {
		load()
	}, [load])

	return [value, loading, error, load]
}


/**
 * loads url immediately and when deps change or when calling load
 * @returns [value, loading, error, load]
 */
export const useAxiosGet = (url, deps = [], config) => {
	return useAsync(() => {
		if (typeof url === 'function') url = url()
		if (url) {
			// devlog('useAxiosGet getting', url);
			return axios.get(url, config)
				.then(r => Promise.resolve(r.data))
				.catch(e => Promise.reject(e))
		} else {
			// devlog('useAxiosGet resolving to null');
			return Promise.resolve(null)
		}
	}, deps)
}


/**
 * loads url only when deps change or when calling load
 * @returns [value, loading, error, load]
 */
export const useUpdateAxiosGet = (url, deps = [], config) => {
	return useUpdateAsync(() => axios.get(url, config)
		.then(r => Promise.resolve(r.data))
		.catch(e => Promise.reject(e))
	, deps)
}


export const useEventListener = (eventType, callback, element) => {
	const callbackRef = useRef(callback)

	useEffect(() => {
		callbackRef.current = callback
	}, [callback])

	useEffect(() => {
		if (!element) return

		const handler = event => callbackRef.current(event)
		element.addEventListener(eventType, handler)

		return () => element.removeEventListener(eventType, handler)
	}, [eventType, element])
}


export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	})

	useEventListener('resize', () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight
		})
	}, window)

	return windowSize
}


/**
 * const elemRef = useRef()
 * const visible = useOnScreen(elemRef, '-100px')
 * ...
 * <div ref={elemRef}> Foo {visible && 'Bar'} </div>
 */
export const useOnScreen = (ref, rootMargin = '-50px') => {
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (ref.current == null) return

		const observer = new IntersectionObserver(
			([entry]) => setVisible(entry.isIntersecting),
			{ rootMargin }
		)
		observer.observe(ref.current)

		return () => ref.current && observer.unobserve(ref.current)
	}, [ref, rootMargin])

	return visible
}


export const useAnimation = (duration = 5000, delay = 0, easingFunction = EASING_FUNCTIONS.linear) => {
	const elapsed = useAnimationTimer(duration, delay)
	const n = Math.min(1, elapsed / duration)
	return easingFunction(n)
}

export const useAnimationTimer = (duration = 1000, delay = 0, trigger = 0) => {
	const [elapsed, setElapsed] = useState(0)

	useEffect(() => {
		let animationFrame, timerStop, start

		function loop() {
			animationFrame = requestAnimationFrame(() => {
				setElapsed(Date.now() - start)
				loop()
			})
		}
		function onStart() {
			timerStop = setTimeout(() => {
				cancelAnimationFrame(animationFrame)
				setElapsed(Date.now() - start)
			}, duration)
			start = Date.now()
			loop()
		}

		const timerDelay = setTimeout(onStart, delay)

		return () => {
			clearTimeout(timerStop)
			clearTimeout(timerDelay)
			cancelAnimationFrame(animationFrame)
		}
	}, [duration, delay, trigger])

	return elapsed
}


export const useRenderCount = () => {
	const count = useRef(1)

	useEffect(() => {count.current++})

	return count.current
}


export const useRenderInfo = (componentName, props) => {
	const count = useRenderCount()
	const changedProps = useRef({})
	const previousProps = useRef(props)
	const lastRenderTimestamp = useRef(Date.now())

	const propKeys = Object.keys({ ...props, ...previousProps.current })
	changedProps.current = propKeys.reduce((obj, key) => {
		if (props[key] === previousProps.current[key]) return obj
		return { ...obj, [key]: { previous: previousProps.current[key], current: props[key] } }
	}, {})
	const info = {
		count,
		changedProps: changedProps.current,
		timeSinceLastRender: Date.now() - lastRenderTimestamp.current,
		lastRenderTimestamp: lastRenderTimestamp.current
	}

	useEffect(() => {
		previousProps.current = props
		lastRenderTimestamp.current = Date.now()
		console.info('🟧[renderInfo]', componentName, info)
	})

	return info
}


export const useOnlineStatus = () => {
	const [online, setOnline] = useState(navigator.onLine)

	useEventListener('online', () => setOnline(navigator.onLine), window)
	useEventListener('offline', () => setOnline(navigator.onLine), window)

	return online
}


export const useLongPress = (ref, callback, { delay = 1000 } = {}) => {
	const { reset, clear } = useTimeout(callback, delay)
	useEffectOnce(clear)

	useEventListener('mousedown', reset, ref.current)
	useEventListener('touchstart', reset, ref.current)
	useEventListener('mouseup', clear, ref.current)
	useEventListener('mouseleave', clear, ref.current)
	useEventListener('touchend', clear, ref.current)
}

