const n2setupPWA = () => {
	const display = display => {
		const elems = document.getElementsByClassName('n2installPWA')
		for (let i=0; i<elems.length; i++) if (elems[i]) elems[i].style.display = display
	}

	window.addEventListener('beforeinstallprompt', e => {
		e.preventDefault()
		window.deferredPrompt = e
		display('')
	})

	window.n2installPWA = async event => {
		window.deferredPrompt.prompt()
		const { outcome } = await window.deferredPrompt.userChoice	// 'dismissed', 'accepted'
		if (outcome === 'accepted') display('none')
		window.deferredPrompt = null
	}

	window.addEventListener('appinstalled', () => {
		window.deferredPrompt = null
		display('none')
	})

	window.n2standalone = () => navigator.standalone || window.matchMedia('(display-mode: standalone)').matches
}

export default n2setupPWA

