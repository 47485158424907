import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Subject } from 'rxjs';
import { APP_API_URL } from '../app/const';
import { devlog, highlight } from './n2functions';


export const n2objectFeed = new Subject()
export const n2eventFeed = new Subject()


export const N2EventFeed = () => {
	// const errors = useRef(0)

	useEffect(() => {
		let sse
		const initEventSource = () => {
			sse = new EventSource(`${APP_API_URL}/sse/user/events`, { withCredentials: true });
			// sse.onerror = e => {
			// 	sse.close()
			// 	errors.current++
			// 	devlog('N2EventFeed errors', errors.current)
			// 	if (errors.current < 10) setTimeout(initEventSource, random(2_000, 5_000) * errors.current)
			// }
			sse.addEventListener('object', e => {
				devlog('/sse/user/events object', e)
				// errors.current = 0
				n2objectFeed.next(JSON.parse(e.data))
			})
			sse.addEventListener('event', e => {
				devlog('/sse/user/events event', e)
				// errors.current = 0
				n2eventFeed.next(JSON.parse(e.data))
			})
		}
		initEventSource()

		return () => sse.close()
	}, [])

	return null
}





export const useUpdateEntity = (url, callback) => {
	const [entity, setEntity] = useState()
	const entityId = useRef()
	const entityModified = useRef()
	const [lastEvent, setLastEvent] = useState()
	const ofs = useRef()
	const onEvent = useRef(callback)

	useEffect(() => {
		onEvent.current = callback
	}, [callback])

	const init = useCallback(entity => {
		setEntity(entity)
		if (entity != null) {
			entityId.current = entity.id
			entityModified.current = entity.modified
		} else {
			entityId.current = null
			entityModified.current = null
		}
	}, [])

	useEffect(() => {
		ofs.current = n2objectFeed.subscribe(msg => {
			devlog('useUpdateEntity objectFeed', entityId.current, entityModified.current, msg)
			if (entityId.current !== msg.id) return
			switch (msg.action) {
				case 'updated':
					if (!msg.modified || !entityModified.current || msg.modified > entityModified.current) {
						axios.get(url).then(r => {
							setEntity(r.data)
							entityModified.current = r.data.modified
							highlight(`n2id_${msg.id}`)
						})
					}
					break
				case 'deleted':
					setEntity(null)
					entityModified.current = null
					break
				default:
			}
			setLastEvent(msg)
			onEvent.current && onEvent.current(msg)
		})

		return () => {
			ofs.current && ofs.current.unsubscribe()
		}
	}, [url])

	return [init, entity, onEvent, lastEvent]
}


export const useEntity = (url, callback) => {
	const [init, entity, onEvent, lastEvent] = useUpdateEntity(url, callback)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		axios.get(url).then(r => {
			init(r.data)
		}).finally(() => setLoading(false))
	}, [init, url])

	return [loading, entity, onEvent, lastEvent]
}


export const useDtoPage = (urlTemplate, parentId) => {
	const [dtoPage, setDtoPage] = useState()
	const ids = useRef()
	const modifieds = useRef()
	const [lastEvent, setLastEvent] = useState()
	const ofs = useRef()

	const init = useCallback(p => {
		setDtoPage(p)
		if (p != null) {
			ids.current = p.dtos.map(dto => dto.id)
			modifieds.current = p.dtos.map(dto => dto.modified)
		}
	}, [])

	useEffect(() => {
		ofs.current = n2objectFeed.subscribe(msg => {
			devlog('useDtoPage objectFeed', msg)
			let url = urlTemplate
			Object.keys(msg).forEach(k => url = url.replace(`{${k}}`, msg[k]))
			switch (msg.action) {
				case 'created': {
					if (parentId != null) {
						if (typeof parentId === 'number') {
							if (parentId !== msg.parentId) break
						} else if (Array.isArray(parentId)) {
							if (!parentId.includes(msg.parentId)) break
						}
					}
					if (ids.current.includes(msg.id)) break
					axios.get(url).then(r => {
						setDtoPage(prev => {
							const p = { ...prev }
							p.dtos.unshift({ ...r.data, _mark: 'created' })
							return p
						})
						ids.current.unshift(msg.id)
						modifieds.current.unshift(r.data.modified)
						highlight(`n2id_${msg.id}`)
					})
					break
				}
				case 'updated': {
					const idx = ids.current.indexOf(msg.id)
					if (idx === -1) break
					if (msg.modified <= modifieds.current[idx]) break
					axios.get(url).then(r => {
						setDtoPage(prev => {
							const p = { ...prev }
							p.dtos[p.dtos.findIndex(o => o.id === r.data.id)] = { ...r.data, _mark: 'updated' }
							return p
						})
						modifieds.current[idx] = r.data.modified
						highlight(`n2id_${msg.id}`)
					})
					break
				}
				case 'deleted': {
					const idx = ids.current.indexOf(msg.id)
					if (idx === -1) break
					setDtoPage(prev => {
						const p = { ...prev }
						p.dtos.splice(idx, 1)
						return p
					})
					ids.current.splice(idx, 1)
					modifieds.current.splice(idx, 1)
					break
				}
				default:
			}
			setLastEvent(msg)
		})

		return () => {
			ofs.current && ofs.current.unsubscribe()
		}
	}, [urlTemplate, parentId])

	return [init, dtoPage, lastEvent]
}


export const useDtoPage2 = ({
			defaultFilter = { perPage: 10, page: 1 },
			dtoPageUrl,
			dtoUrlTemplate,
		}, parentId) => {

	const [filter, setFilter] = useState({ perPage: 10, page: 1, ...defaultFilter })
	const [loading, setLoading] = useState(false)
	const [setPage, page] = useDtoPage(dtoUrlTemplate, parentId)

	const loadPage = useCallback(() => {
		setLoading(true)
		const s = new URLSearchParams(filter)
		axios.get(`${dtoPageUrl}?${s}`).then(r => {
			setPage(r.data)
		}).finally(() => setLoading(false))
	}, [dtoPageUrl, filter, setPage])

	useEffect(() => {
		loadPage()
	}, [loadPage])

	const onChangePage = useCallback(page => {
		setFilter(prev => ({ ...prev, page }))
	}, [])

	return [filter, setFilter, loading, page, onChangePage]
}





export function useQueryEntity(queryKey, queryUrl, queryOptions, onEvent) {
	const queryClient = useQueryClient()
	const entityRef = useRef()
	const [lastEvent, setLastEvent] = useState()
	const onEventRef = useRef()

	useEffect(() => onEventRef.current = onEvent, [onEvent])

	useEffect(() => {
		const ofs = n2objectFeed.subscribe(msg => {
			devlog('useQueryEntity objectFeed', msg)
			if (entityRef.current?.id !== msg.id) return
			switch (msg.action) {
				case 'updated':
					queryClient.invalidateQueries(queryKey, { exact: true })
					highlight(`n2id_${msg.id}`)
					break
				case 'deleted':
					entityRef.current = null
					queryClient.removeQueries(queryKey, { exact: true })
					break
				default:
			}
			setLastEvent(msg)
			onEventRef.current && onEventRef.current(msg)
		})

		return () => ofs?.unsubscribe()
	}, [queryClient, queryKey])

	const options = { ...queryOptions }
	if (options.enabled == null) options.enabled = true
	options.enabled = options.enabled && !!queryUrl

	const query = useQuery(queryKey, async () => {
		const data = (await axios.get(queryUrl)).data
		entityRef.current = data
		return data
	}, options)

	return [query, lastEvent]
}


export function useQueryPage({
			parentIds,
			defaultFilter = { perPage: 10, page: 1 },
		}, queryKey, queryUrl, queryOptions, onEvent) {

	const [filter, setFilter] = useState({ perPage: 10, page: 1, ...defaultFilter })
	const queryClient = useQueryClient()
	const parentIdsRef = useRef()
	const pageRef = useRef()
	const [lastEvent, setLastEvent] = useState()
	const onEventRef = useRef()

	useEffect(() => {
		parentIdsRef.current = Array.isArray(parentIds) ? parentIds : (typeof parentId === 'number' ? [parentIds] : [])
	}, [parentIds])

	useEffect(() => {
		onEventRef.current = onEvent
	}, [onEvent])

	useEffect(() => {
		const ofs = n2objectFeed.subscribe(msg => {
			devlog('useQueryPage objectFeed', msg)
			switch (msg.action) {
				case 'created':
					if (parentIdsRef.current.includes(msg.parentId)) {
						queryClient.invalidateQueries(queryKey)
					}
					break
				case 'updated':
					const idx = pageRef.current?.dtos.findIndex(e => e.id === msg.id)
					if (idx >= 0 && msg.modified > pageRef.current.dtos[idx].modified) {
						queryClient.invalidateQueries(queryKey)
					}
					break
				case 'deleted':
					if (pageRef.current?.dtos.find(e => e.id === msg.id)) {
						queryClient.removeQueries(queryKey)
					}
					break
				default:
			}
			setLastEvent(msg)
			onEventRef.current && onEventRef.current(msg)
		})

		return () => ofs?.unsubscribe()
	}, [queryClient, queryKey])

	const options = { ...queryOptions }
	if (options.enabled == null) options.enabled = true
	options.enabled = options.enabled && !!queryUrl

	const key = Array.isArray(queryKey) ? queryKey : [queryKey]
	key.push(parentIds)
	key.push(filter)

	const query = useQuery(key, async () => {
		const s = new URLSearchParams(filter)
		const data = (await axios.get(`${queryUrl}?${s}`)).data
		pageRef.current = data
		return data
	}, options)

	const onChangePage = useCallback(page => {
		setFilter(prev => ({ ...prev, page }))
	}, [])

	return { filter, setFilter, query, lastEvent, onChangePage }
}

