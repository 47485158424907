import React from 'react'
import './N2Tuple.scss'


export const N2Tuple = ({ label, value, children }) => {
	const v = value || children
	return v != null ? (
		<div className="N2Tuple"><label>{label}</label>{v}</div>
	) : null
}


export function N2Tuples({ children }) {
	return <div className="N2Tuples">{children}</div>
}

