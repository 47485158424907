import React from 'react';
import { APP_COLORS } from '../app/const';


const RankAvatar = ({ rank, color }) => {
	const c = color || APP_COLORS.ranks[rank]
	const fill = `url(#rankGradient${rank})`
	const stroke = APP_COLORS.ranksDark[rank]
	return (
		<svg className="RankAvatar" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient id={`rankGradient${rank}`} x1="0%" y1="0%" x2="30%" y2="100%" spreadMethod="pad">
      				<stop offset="0%" stopColor={c}></stop>
      				<stop offset="30%" stopColor={c}></stop>
	   				<stop offset="50%" stopColor="#FFFFFF"></stop>
					<stop offset="80%" stopColor={c}></stop>
    				<stop offset="100%" stopColor={c}></stop>
				</linearGradient>
			</defs>
			<g transform="matrix(1,0,0,1,0,-1)">
    		    <circle fill={fill} stroke={stroke} strokeWidth={4} cx="50" cy="50" r="45"/>
    		</g>
		</svg>
	)
}


export default RankAvatar
