import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import './N2Pagination.scss';


function N2Pagination({ dtoPage, onChangePage }) {
	const intl = useIntl()

	const handleEvent = useCallback((e, page) => {
		onChangePage(page)
	}, [onChangePage])

	const begin = Math.max(0, (dtoPage.page-1) * dtoPage.perPage+1)
	const end = Math.min(begin+dtoPage.perPage-1, dtoPage.rows)
	const info = `${dtoPage.ms}ms @ `+ intl.formatDate(dtoPage.timestamp, {format: 'dateTime'})
	return <Grid container direction="row" alignItems="center" className="N2Pagination">
		<Grid item>
			<span title={info} className="info">{begin}{end !== begin ? `-${end}` : ''} / {dtoPage.rows}</span>
		</Grid>
		<Grid item>
			<Pagination count={dtoPage.pages} page={dtoPage.page} onChange={handleEvent} showFirstButton showLastButton hidePrevButton hideNextButton color="primary" />
		</Grid>
	</Grid>
}

export default N2Pagination
