import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import N2Expiry from './N2Expiry';
import { devlog } from './n2functions';
import { N2I18N2 } from './N2I18N2';


const n2newAppVersion = new Subject()


export function updateServiceWorker() {
	if (navigator.serviceWorker) {
		navigator.serviceWorker.getRegistrations().then(regs => {
			regs.forEach(reg => {
				reg.update()
				if (reg.waiting != null) newAppVersionAvailable()
			})
		})
	}
}


export function newAppVersionAvailable() {
	n2newAppVersion.next(true)
}


export function skipWaitingAndExec(fun) {
	navigator.serviceWorker.getRegistrations().then(regs => {
		regs.forEach(reg => {
			if (reg.waiting != null) reg.waiting.postMessage({ type: 'SKIP_WAITING' })
			// workbox removes old files from cache storage upon activation of new SW
		})
	})
	setTimeout(fun, 750)
}

export function skipWaitingAndReload() {
	skipWaitingAndExec(() => window.location.reload());
}


export default function N2NewAppVersion() {
	const [expiry, setExpiry] = useState()

	useEffect(() => {
		const sub = n2newAppVersion.subscribe(msg => {
			devlog('NewAppVersion n2newAppVersion', msg)
			if (msg === true) {
				setExpiry(prev => prev ? prev : moment().add(5, 'minutes'))
			} else setExpiry(null)
		})
		return () => sub.unsubscribe()
	}, [])

	return expiry ? <div className="N2NewAppVersion">
		<Alert variant="filled" severity="warning" action={<Button onClick={skipWaitingAndReload}><N2I18N2 id="client.newAppversion@load" def="LOAD NOW"/></Button>}>
			<N2I18N2 id="client.newAppversion@text" def="New app version available. Loading in"/> <N2Expiry when={expiry} onExpiry={skipWaitingAndReload} />
		</Alert>
	</div> : null
}

