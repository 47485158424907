import PropTypes from 'prop-types';
import React from 'react';
import './N2Skeleton.scss';


const N2Skeleton = props => {
	const aniDur = () => 0.4 + Math.random() / 2

	const style = {}
	if (props.width) style.width = props.width
	if (props.height) style.height = props.height

	let __html = ''
	if (props.type === 'text') {
		const pattern = props.pattern || 'title 2-line-100% 1-line-60%'
		pattern.split(' ').forEach(p => {
			if (p === 'title') {
				__html += `<div class="N2Skeleton-text-title" style="animation-duration: ${aniDur()}s"></div>`
			} else if (p === 'image') {
				__html += `<div class="N2Skeleton-text-image" style="animation-duration: ${aniDur()}s"></div>`
			} else {
				const m = p.match(/^([0-9]+)-line-([0-9]+%)/)
				if (m) {
					const repeat = +m[1]
					const width = m[2]
					for (let i=0; i<repeat; i++) {
						__html += `<div class="N2Skeleton-text-line" style="width: ${width}; animation-duration: ${aniDur()}s"></div>`
					}
				}
			}
		})

	} else if (props.type === "list") {
		const pattern = props.pattern || '20% grow'
		const lines = props.lines || 9
		for (let i=0; i<lines; i++) {
			let line = ''
			pattern.split(' ').forEach(p => {
				if (p === 'avatar') {
					line += `<div class="N2Skeleton-listitem-avatar" style="animation-duration: ${aniDur()}s"></div>`
				} else if (p === 'grow') {
					line += `<div class="N2Skeleton-listitem-text" style="flex-grow: 1; animation-duration: ${aniDur()}s"></div>`
				} else if (p.match(/^[0-9]+%$/)) {
					line += `<div class="N2Skeleton-listitem-text" style="width: ${p}; animation-duration: ${aniDur()}s"></div>`
				}
			})
			__html += `<div class="N2Skeleton-listitem">${line}</div>`
		}
	}

	return <div dangerouslySetInnerHTML={{ __html }} style={style}></div>
}

N2Skeleton.propTypes = {
	type: PropTypes.oneOf(['text', 'list']),
	pattern: PropTypes.string,
	lines: PropTypes.number
}

export default N2Skeleton
