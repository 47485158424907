import { createTheme } from "@material-ui/core"
import { deepOrange, green } from "@material-ui/core/colors"
import { useMemo } from "react"


export const useAppTheme = () => {
	const prefersDarkMode = false // useMediaQuery('(prefers-color-scheme: dark)')

	return useMemo(() => createTheme({
		palette: {
			primary: green,
			secondary: deepOrange,
			type: prefersDarkMode ? 'dark' : 'light',
			background: {
				default: '#eee'
			},
		},
		props: {
			MuiTextField: {
				variant: 'outlined',
			},
			MuiButton: {
				disableElevation: true
			},
		},
		overrides: {
			MuiCard: {
				root: {
					borderRadius: 8,
				},
			},
			MuiFab: {
				root: {
					position: 'fixed',
					bottom: 16,
					right: 24,
					zIndex: 1,
				},
			},
		},
	}), [prefersDarkMode])
}
