import { useMemo } from "react"
import { COUNTRIES } from "../n2shared/countries"
import { useN2I18N2 } from "../n2shared/N2I18N2"

export const APP_API_URL = process.env.REACT_APP_API_URL

export const LOCALES = {
	// don't forget countries.js !
	de: {name: 'Deutsch', flag: 'de'},
	en: {name: 'English', flag: 'gb'},
	hu: {name: 'Magyar', flag: 'hu'},
}

export const I18N_PREFIXES = ['common', 'domain', 'client']

export const INTL_FORMATS = {
	number: {
		EUR: { style: 'currency', currency: 'EUR' },
	},
	date: {
		dateTime: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', hour12: false, minute: 'numeric' },
		dateTimeMillis:  { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', hour12: false, minute: 'numeric', second: 'numeric', fractionalSecondDigits: 3 },
		timeHM:  { hour: 'numeric', hour12: false, minute: 'numeric' },
		timeHMS:  { hour: 'numeric', hour12: false, minute: 'numeric', second: 'numeric' },
		monthYear: { year: 'numeric', month: 'long' },
		dayMonth: { day: 'numeric', month: 'short' },
	}
}

export const APP_COLORS = {
	ranksOrig: ['#EBEBEB', '#FFF2CB', '#FFE598', '#E2EEDA', '#C5DEB5', '#DEEAF6', '#9CC2E5', '#FBE4D5', '#F4B083', '#B15D24'],
	ranks: ['#CCCCCC', '#FFE699', '#FFD24C', '#ABD98C', '#7ECB4D', '#A8CCF0', '#529CE0', '#F9C39F', '#EB8847', '#AF5518'],
	ranksDark: ['#666666', '#CC9900', '#805F00', '#457326', '#223B11', '#1F66AD', '#0F3457', '#C0530C', '#5C2B0A', '#2D1606'],
}

export const APP_RANKS = ['start', 'distributor', 'supervisor', 'teamleader', 'juniordirector', 'director', 'seniordirector', 'president', 'silverpresident', 'goldpresident']


export function useCountries() {
	const n2i18n2 = useN2I18N2()

	const countries = useMemo(() => {
		const l = n2i18n2?.locale || 'en'
		const first = ['DE', 'AT', 'CH', 'LI', 'HU']
		const cs = {}
		first.forEach(c => cs[c] = COUNTRIES[l][c])
		Object.keys(COUNTRIES[l]).filter(k => !first.includes(k)).forEach(c => cs[c] = COUNTRIES[l][c])
		return cs
	}, [n2i18n2])

	return countries
}
