import { useContext } from "react"
import { AppProvider } from "./N2App"


export const useRequireRole = (roles, onUnauthorized) => {
	const { requireRole } = useContext(AppProvider)

	requireRole(roles, onUnauthorized)
}

