import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import N2App from './n2shared/N2App'
import { newAppVersionAvailable } from './n2shared/N2NewAppVersion'
import n2setupPWA from './n2shared/n2pwa'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<N2App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
	onUpdate: registration => {
		newAppVersionAvailable()
	},
})

// navigator.serviceWorker.getRegistrations().then(registrations => {
// 	for (let registration of registrations) registration.unregister()
// })

if ('caches' in window) {
	caches.keys().then(keyList => Promise.all(keyList.map(key => caches.delete(key))))
}

n2setupPWA()
