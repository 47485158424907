import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Radio, TextField, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import moment from 'moment';
import * as Prism from 'prismjs';
import 'prismjs/components/prism-icu-message-format';
import 'prismjs/components/prism-velocity';
import 'prismjs/themes/prism-tomorrow.css';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedDate, FormattedNumber, IntlProvider, useIntl } from 'react-intl';
import { useNavigation } from 'react-navi';
import Editor from 'react-simple-code-editor';
import { Subject } from 'rxjs';
import { APP_API_URL, I18N_PREFIXES, LOCALES } from '../app/const';
import dexie from '../app/dexie';
import { AppProvider } from './N2App';
import { n2broadcastFeed } from './N2BroadcastFeed';
import N2Clipboard from './N2Clipboard';
import { useN2Confirm } from './N2Confirm2';
import N2Dropzone from './N2Dropzone';
import './N2I18N2.scss';
import N2Pagination from './N2Pagination';
import { showSnackbar } from './N2Snackbar';
import { N2Content, N2Toolbar, N2View } from './N2View';
import { useRequireRole } from './n2appHooks';
import { abbrMid, array2date, devlog, getStoredLocale, html2text, inputError, setStoredLocale } from './n2functions';



const i18n2Subj = new Subject()

const i18n2 = {
	load: clear => i18n2Subj.next({ action: 'load', clear }),
	updateMessages: locale => i18n2Subj.next({ action: 'updateMessages', locale: locale || getStoredLocale() }),
}


export const useN2I18N2 = () => {
	const intl = useIntl()
	const { isInRole } = useContext(AppProvider)

	const n2i18n2 = useMemo(() => ({
		locale: intl.locale,

		clear: () => i18n2.load(true),

		formatMessage: (id, def, values) => {
			// console.log(intl.messages._loaded, intl.messages);
			if (intl.locale && intl.messages._loaded && !intl.messages[id] && def) {
				intl.messages = {
					...intl.messages,
					[id]: def
				}
				if (isInRole('ROOT', 'I18N') && intl.locale === 'en') {
					axios.post(`${APP_API_URL}/pub/i18n2/missing`, {
						lang: intl.locale,
						key: id,
						value: def
					})
				}
			}
			return intl.formatMessage({ id, defaultMessage: def }, values)
		},

		formatNumberShort: num => {
			const h = 100, k = 1000, m = k * 1000
			const n = intl.formatNumber(num, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
			if (num < h) return n
			else if (num < k) return <FormattedNumber value={num} minimumFractionDigits={1} maximumFractionDigits={1} />
			else if (num < m) return <abbr title={n}><FormattedNumber value={num/k} minimumFractionDigits={1} maximumFractionDigits={1} /><span className="n2numberUnit">k</span></abbr>
			else return <abbr title={n}><FormattedNumber value={num/m} minimumFractionDigits={2} maximumFractionDigits={2} /><span className="n2numberUnit">M</span></abbr>
		},

		formatBytes: bytes => {
			const kb = 1024, mb = kb * 1024, gb = mb * 1024
			if (bytes < kb) return <><FormattedNumber value={bytes} minimumFractionDigits={0} maximumFractionDigits={0} /><span className="n2numberUnit">B</span></>
			else if (bytes < mb) return <><FormattedNumber value={bytes/kb} minimumFractionDigits={1} maximumFractionDigits={1} /><span className="n2numberUnit">kB</span></>
			else if (bytes < gb) return <><FormattedNumber value={bytes/mb} minimumFractionDigits={1} maximumFractionDigits={1} /><span className="n2numberUnit">MB</span></>
			else return <><FormattedNumber value={bytes/gb} minimumFractionDigits={2} maximumFractionDigits={2} /><span className="n2numberUnit">GB</span></>
		}
	}), [intl, isInRole])

	return n2i18n2
}


export const N2I18N2 = ({ id, def, values, html, className }) => {
	const n2i18n2 = useN2I18N2()

	const msg = n2i18n2.formatMessage(id, def, values)
	const attrs = {
		i18n2: id,
		className,
		onContextMenu: event => {
			// console.log(event)
			if (event.ctrlKey && event.altKey) {
				event.stopPropagation()
				event.preventDefault()
				navigator.clipboard.writeText(id)
				showSnackbar(`'${id}' in clipboard`)
			}
		}
	}

	return html
		? <span dangerouslySetInnerHTML={{ __html: msg }} {...attrs} />
		: <span {...attrs}>{msg}</span>
}


const N2I18N2ErrorHandler = err => {
	devlog('[i18n2]', err)
}


export const N2I18N2Provider = ({ locale, formats, children }) => {
	const [i18n, setI18n] = useState()

	const updateMessages = useCallback(locale => {
		devlog('N2I18N2Provider updateMessages', locale)
		moment.locale(locale)
		const messages = { _loaded: new Date() }
		dexie.i18n.where('lang').equals(locale).each(row => messages[row.key] = row.value).then(() => {
			setI18n({ locale, messages })
			setStoredLocale(locale)
		})
	}, [])

	const load = useCallback(clear => {
		devlog('N2I18N2Provider load')
		const info = JSON.parse(localStorage.getItem('N2I18N2Provider')) || { loaded: 0, cleared: 0 }
		// clear = clear || moment.utc(info.cleared).isBefore( moment.utc().subtract(random(20, 28), 'hours') )
		const since = clear ? 0 : moment.utc(info.loaded).valueOf()
		// const c = {
		// 	validateStatus: status => (status>=200 && status<300) || status===304,
		// 	headers: { 'If-Modified-Since': m.toDate().toUTCString() }
		// }
		const s = new URLSearchParams({ prefixes: I18N_PREFIXES, since })
		axios.get(`${APP_API_URL}/pub/i18n2?${s}`).then(async r => {
			devlog(`N2I18N2Provider loaded ${r.data.length} resources, x-i18n2=${r.headers['x-i18n2']}`)
			info.loaded = moment().utc()
			if (clear || r.headers['x-i18n2']==='clear') {
				await dexie.i18n.clear()
				info.cleared = moment().utc()
				devlog('N2I18N2Provider dexie.i18n cleared')
			}
			await dexie.i18n.bulkPut(r.data)
			localStorage.setItem('N2I18N2Provider', JSON.stringify(info))
			i18n2.updateMessages()
		})
	}, [])

	useEffect(() => {
		devlog("N2I18N2 subscribing to n2broadcastFeed")
		const bfs = n2broadcastFeed.subscribe(msg => {
			if (msg.action === 'i18n') {
				const delay = Math.round(Math.random() * 1000 * 60)
				devlog('N2I18N2 n2broadcastFeed', msg, delay)
				i18n2Subj.next(msg.data)
			}
		})
		return () => {
			devlog("N2I18N2 unsubscribing from n2broadcastFeed")
			if (bfs) bfs.unsubscribe()
		}
	}, [])

	useEffect(() => {
		devlog('N2I18N2Provider locale changed', locale)
		updateMessages(locale)
	}, [locale, updateMessages])

	useEffect(() => {
		const sub = i18n2Subj.subscribe(msg => {
			devlog('N2I18N2Provider i18nSubj', msg)
			switch(msg.action) {
				case 'updateMessages':
					updateMessages(msg.locale);
					break
				case 'load':
					load(msg.clear);
					break
				case 'created':
				case 'updated':
					dexie.i18n.put(msg.resource).then()
					i18n2.updateMessages()
					break
				case 'deleted':
					dexie.i18n.delete(msg.valueId).then()
					i18n2.updateMessages()
					break
				default:
			}
		})
		return () => sub.unsubscribe()
	}, [load, updateMessages])

	useEffect(() => {
		load()
	}, [load])

	return i18n ? <IntlProvider locale={i18n.locale} formats={formats} messages={i18n.messages} onError={N2I18N2ErrorHandler}>
		{children}
	</IntlProvider> : null
}

// ----------------------------------------------------------------------------------------------------

const LanguageBadge = ({ lang }) => {
	const c = LOCALES[lang]?.flag || lang
	return lang ? <span className="languageBadge">
		{LOCALES[lang] && <img src={`/assets/flags/${c}.svg`} alt={c} className="flag" />}
		{lang}
	</span> : null
}

export const N2AdminI18N2s = ({ query }) => {
	useRequireRole(['I18N', 'ROOT'])

	const navigation = useNavigation()
	const intl = useIntl()
	const { auth } = useContext(AppProvider)
	const [menuAnchor, setMenuAnchor] = useState()
	const { register, handleSubmit } = useForm()
	const [resources, setResources] = useState()

	const hasQuery = query!=null && (query['filter.lang']!=null || query['filter.search']!=null)
	const storedFilter = JSON.parse(localStorage.getItem('i18n.filter'))
	const [filter, setFilter] = useState({
		lang: hasQuery ? query['filter.lang'] || '' : storedFilter?.lang ?? intl.locale ?? '',
		search: hasQuery ? query['filter.search'] || '' : storedFilter?.search ?? '',
		perPage: hasQuery ? 10 : storedFilter?.perPage || 10,
		page: hasQuery ? 1 : storedFilter?.page || 1
	})

	const load = useCallback(() => {
		localStorage.setItem('i18n.filter', JSON.stringify(filter))
		const s = new URLSearchParams(filter)
		axios.get(`${APP_API_URL}/i18n2?${s}`).then(r => {
			setResources(r.data)
		})
	}, [filter])

	const pageChange = useCallback(page => {
		setFilter(prev => ({ ...prev, page }))
	}, [])

	const init = useCallback(() => {
		axios.get(`${APP_API_URL}/i18n2/init`)
	}, [])

	const clear = useCallback(() => {
		i18n2.load(true)
	}, [])

	const edit = useCallback(res => {
		navigation.navigate(`/admin/i18n2/${res.valueId}`)
	}, [navigation])

	useEffect(() => {
		const sub = i18n2Subj.subscribe(msg => {
			devlog('N2AdminI18N2s i18nSubj', msg)
			if (!resources?.dtos) return
			switch (msg.action) {
				case 'created': load(); break
				case 'updated': if (resources.dtos.find(r => r.valueId === msg.resource.valueId && array2date(msg.resource.modified) > array2date(r.modified))) load(); break
				case 'deleted': if (resources.dtos.find(r => r.valueId === msg.valueId)) load(); break
				default:
			}
		})
		return () => sub.unsubscribe()
	}, [load, resources])

	useEffect(() => {
		if (auth) load()
	}, [auth, load])

	return <N2View className="N2I18N2s">
		<N2Toolbar left={<h3 className="n2grow">I18N<sup>2</sup></h3>}>
			<form onSubmit={handleSubmit(setFilter)} noValidate>
				<TextField name="lang" inputRef={register()} defaultValue={filter.lang} placeholder="Language" margin="dense" style={{ width: 60 }} />
				<TextField name="search" inputRef={register()} defaultValue={filter.search} placeholder="Search" margin="dense"  style={{ width: 120 }} />
				<IconButton type="submit"><Icon>search</Icon></IconButton>
				<IconButton edge="end" onClick={e => setMenuAnchor(e.currentTarget)}><Icon>more_vert</Icon></IconButton>
			</form>
		</N2Toolbar>
		<Menu keepMounted anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={e => setMenuAnchor(null)}>
			<MenuItem onClick={adminI18N2ExportDialog.show}>Export</MenuItem>
			<MenuItem onClick={adminI18N2ImportDialog.show}>Import</MenuItem>
			<MenuItem onClick={init}>Init (Server)</MenuItem>
			<MenuItem onClick={clear}>Clear (this Client)</MenuItem>
		</Menu>
		<N2Content>
			{resources && <>
				<List>
					{resources.dtos.map((r, i) => <ListItem button key={r.valueId} onClick={e => edit(r)}>
						<ListItemAvatar><span>{i + 1}</span></ListItemAvatar>
						<ListItemText primary={<>
							<LanguageBadge lang={r.lang} />
							{r.key}
						</>} secondary={<span className="valuePreview">{abbrMid(html2text(r.value), 70)}</span>} />
					</ListItem>)}
				</List>
				<N2Pagination dtoPage={resources} onChangePage={pageChange} />
			</>}
			<Fab onClick={adminI18N2AddDialog.show} color="primary"><Icon>add</Icon></Fab>
		</N2Content>
		<N2AdminI18N2ExportDialog />
		<N2AdminI18N2ImportDialog />
		<N2AdminI18N2AddDialog />
	</N2View>
}


const adminI18N2ExportDialogSubj = new Subject()
const adminI18N2ExportDialog = {
	show: () => adminI18N2ExportDialogSubj.next({ open: true }),
	hide: () => adminI18N2ExportDialogSubj.next({ open: false }),
}
const N2AdminI18N2ExportDialog = () => {
	const { register, handleSubmit } = useForm({
		defaultValues: {
			langA: 'en',
			langBcsv: Object.keys(LOCALES).filter(l => l !== 'en').join(',')
		}
	})
	const [state, setState] = useState({ open: false })

	useEffect(() => {
		const subs = adminI18N2ExportDialogSubj.subscribe(setState)
		return () => subs.unsubscribe()
	}, [])

	const onSubmit = useCallback(d => {
		const s = new URLSearchParams(d)
		window.open(`${APP_API_URL}/i18n2/xlsx?${s}`)
		adminI18N2ExportDialog.hide()
	}, [])

	return <Dialog open={state.open} onClose={adminI18N2ExportDialog.hide}>
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<DialogTitle>Export</DialogTitle>
			<DialogContent>
				<TextField name="keyRegexs" inputRef={register()} label="Key Regexes (1 per line, empty = all keys)" multiline minRows={3} maxRows={10} autoFocus margin="dense" fullWidth />
				<Grid container direction="row" spacing={1}>
					<Grid item><TextField name="langA" inputRef={register()} label="From Language" margin="dense" fullWidth /></Grid>
					<Grid item><TextField name="langBcsv" inputRef={register()} label="To Language(s) [CSV]" margin="dense" fullWidth /></Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={adminI18N2ExportDialog.hide}>Cancel</Button>
				<Button type="submit" color="primary">Export</Button>
			</DialogActions>
		</form>
	</Dialog>
}


const adminI18N2ImportDialogSubj = new Subject()
const adminI18N2ImportDialog = {
	show: () => adminI18N2ImportDialogSubj.next({ open: true }),
	hide: () => adminI18N2ImportDialogSubj.next({ open: false }),
}
const N2AdminI18N2ImportDialog = () => {
	const [state, setState] = useState({ open: false })
	const [lang, setLang] = useState('xx')

	useEffect(() => {
		const subs = adminI18N2ImportDialogSubj.subscribe(setState)
		return () => subs.unsubscribe()
	}, [])

	const onUploaded = useCallback(r => {
		adminI18N2ImportDialog.hide()
	}, [])

	return <Dialog open={state.open} onClose={adminI18N2ImportDialog.hide}>
		<DialogTitle>Import</DialogTitle>
		<DialogContent>
			<TextField label="Language" defaultValue={lang} onChange={e => setLang(e.currentTarget.value)} autoFocus margin="dense"/>
			<p>Column 0 = Key, Column 2 = {lang}-Translation</p>
			<N2Dropzone url={`${APP_API_URL}/i18n2/xlsx`}
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				multiple={false}
				params={() => ({ lang })}
				onUploaded={onUploaded} />
		</DialogContent>
		<DialogActions>
			<Button onClick={adminI18N2ImportDialog.hide}>Cancel</Button>
		</DialogActions>
	</Dialog>
}


const adminI18N2AddDialogSubj = new Subject()
const adminI18N2AddDialog = {
	show: () => adminI18N2AddDialogSubj.next({ open: true }),
	hide: () => adminI18N2AddDialogSubj.next({ open: false }),
}
const N2AdminI18N2AddDialog = ({ defaultLang, defaultKey }) => {
	const navigation = useNavigation()
	const intl = useIntl()
	const [state, setState] = useState({ open: false })
	const { register, handleSubmit, errors } = useForm({ defaultValues: { lang: defaultLang || intl.locale, key: defaultKey || '' }})

	useEffect(() => {
		const subs = adminI18N2AddDialogSubj.subscribe(setState)
		return () => subs.unsubscribe()
	}, [])

	const onSubmit = useCallback(data => {
		axios.post(`${APP_API_URL}/i18n2`, data).then(r => {
			navigation.navigate(`/admin/i18n2/${r.data.valueId}`)
		})
	}, [navigation])

	return <Dialog open={state.open} onClose={adminI18N2AddDialog.hide}>
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<DialogTitle>Add</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={4}>
						<TextField name="lang" inputRef={register({ required: true })}
							label="Language" fullWidth {...inputError(errors,'lang')} />
					</Grid>
					<Grid item xs={12} sm={8}>
						<TextField name="key" inputRef={register({ required: true })}
							label="Key" fullWidth {...inputError(errors,'lang')} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={adminI18N2AddDialog.hide}>Cancel</Button>
				<Button type="submit" color="primary">Add</Button>
			</DialogActions>
		</form>
	</Dialog>
}


export const N2AdminI18N2 = ({ valueId }) => {
	useRequireRole(['I18N', 'ROOT'])

	const navigation = useNavigation()
	const showConfirm = useN2Confirm()
	const [menuAnchor, setMenuAnchor] = useState()
	const [state, setState] = useState()
	const [stale, setStale] = useState(false)
	const [otherLanguages, setOtherLanguages] = useState()
	const [related, setRelated] = useState()
	const [deeplBusy, setDeeplBusy] = useState(false)

	const load = useCallback(() => {
		axios.get(`${APP_API_URL}/i18n2/I18N2V/${valueId}`).then(r => {
			const code = r.data.value
			const language = code.includes('${') || code.includes('/>') ? 'velocity' : 'icu-message-format'
			setState({ resource: r.data, code, language })
			setStale(false)
			const s1 = new URLSearchParams({ lang: '', search: `key=${r.data.key}`, perPage: 999, page: 1})
			axios.get(`${APP_API_URL}/i18n2?${s1}`).then(r => {
				setOtherLanguages(r.data.dtos
					.filter(r => r.valueId !== valueId)
					.sort((a, b) => a.lang.localeCompare(b.lang)))
			})
			const atPos = r.data.key.lastIndexOf('@')
			const dotPos = r.data.key.lastIndexOf('.')
			const pos = atPos>=0 ? atPos : dotPos>=0 ? dotPos : -1
			if (pos >= 0) {
				const search = r.data.key.slice(0, pos)
				const s2 = new URLSearchParams({ lang: r.data.lang, search, perPage: 999, page: 1})
				axios.get(`${APP_API_URL}/i18n2?${s2}`).then(r => {
					setRelated({
						search,
						resources: r.data.dtos
							.filter(r => r.valueId!==valueId)
							.sort((a, b) => a.key.localeCompare(b.key))
					})
				})
			}
		})
	}, [valueId])

	const back = useCallback(() => {
		if (state?.dirty) {
			showConfirm({ text: 'Close without saving?' }).then(() => {
				navigation.navigate('/admin/i18n2')
			})
		} else {
			navigation.navigate('/admin/i18n2')
		}
	}, [navigation, showConfirm, state])

	const changeLanguage = useCallback(e => {
		e.persist()
		setState(prev => ({ ...prev, language: e.target.value }))
	}, [])

	const setCode = useCallback(code => {
		setState(prev => ({ ...prev, code, dirty: true }))
	}, [])

	const save = useCallback(() => {
		axios.patch(`${APP_API_URL}/i18n2/I18N2V/${valueId}`, { value: state.code }).then(r => {
			showSnackbar('saved')
			setState(prev => ({ ...prev, resource: r.data, code: r.data.value, dirty: false }))
			setStale(false)
		})
	}, [state, valueId])

	const deleteValue = useCallback(() => {
		setMenuAnchor(null)
		showConfirm({ text: 'Really delete this I18N2V?' }).then(() => {
			axios.delete(`${APP_API_URL}/i18n2/I18N2V/${valueId}`).then(r => {
				showSnackbar(`deleted ${r.data} nodes`)
				back()
			})
		})
	}, [back, showConfirm, valueId])

	const deleteKey = useCallback(() => {
		setMenuAnchor(null)
		showConfirm({ text: `Really delete ${state.resource.key} and all language I18N2V?` }).then(() => {
			axios.delete(`${APP_API_URL}/i18n2/I18N2K/${state.resource.keyId}`).then(r => {
				showSnackbar(`deleted the I18N2K and ${r.data.length} I18N2Vs`)
				back()
			})
		})
	}, [back, showConfirm, state])

	const showResource = useCallback(res => {
		navigation.navigate(`/admin/i18n2/${res.valueId}`)
	}, [navigation])

	const deepl = useCallback(async () => {
		setDeeplBusy(true)
		const s = new URLSearchParams({
			auth_key: '86bfdc4e-2982-6aa2-548a-60c66d05d454:fx',
			target_lang: state.resource.lang.toUpperCase(),
			text: state.code
		})
		const r = await fetch(`https://api-free.deepl.com/v2/translate?${s}`)
		setDeeplBusy(false)
		if (r.ok) {
			const data = await r.json()
			setCode(data.translations[0]?.text)
		}
	}, [state, setCode])

	useEffect(() => {
		const sub = i18n2Subj.subscribe(msg => {
			devlog('N2AdminI18N2 i18nSubj', msg, state?.resource)
			if (state?.resource && msg.resource?.valueId===valueId) {
				switch(msg.action) {
					case 'updated': if (array2date(msg.resource.modified) > array2date(state.resource.modified)) setStale(true); break
					case 'deleted': back(); break
					default:
				}
			}
		})
		return () => sub.unsubscribe()
	}, [back, state, valueId])

	useEffect(() => {
		load()
	}, [load])

	return state?.resource ? <N2View className="N2I18N2">
		<N2Toolbar left={<IconButton edge="start" onClick={back}><Icon>close</Icon></IconButton>}>
			<Button onClick={save} variant={state?.dirty ? 'contained' : 'outlined'} color="primary">Save</Button>
			<IconButton edge="end" onClick={e => setMenuAnchor(e.currentTarget)}><Icon>more_vert</Icon></IconButton>
		</N2Toolbar>
		<Menu keepMounted anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={e => setMenuAnchor(null)}>
			<MenuItem onClick={adminI18N2KCloneDialog.show}>Clone {abbrMid(state.resource.key, 40)}</MenuItem>
			<MenuItem onClick={deleteKey}>Delete {abbrMid(state.resource.key, 40)}</MenuItem>
			<MenuItem onClick={adminI18N2VCloneDialog.show}>Clone this :I18N2V {state.resource.lang}</MenuItem>
			<MenuItem onClick={deleteValue}>Delete this :I18N2V {state.resource.lang}</MenuItem>
		</Menu>
		{stale && <div className="n2topAlert">
			<Alert variant="filled" severity="warning" action={<Button onClick={e => load()}>LOAD</Button>}>Stale Data!</Alert>
		</div>}
		<N2Content>
			{state && <form>
				<Grid container alignItems="center" spacing={2}>
					<Grid item><LanguageBadge lang={state.resource.lang} /></Grid>
					<Grid item><Typography variant="h6">{state.resource.key}</Typography></Grid>
				</Grid>
				<N2Clipboard text={`MATCH (k:I18N2K)-[l:I18N2L]->(v:I18N2V) WHERE id(v)=${valueId} RETURN *`} look="simple">
					<table className="nodes"><tbody><tr>
						<td><span className="nodes_node">:I18N2K {state.resource.keyId}</span></td>
						<td>⎯</td>
						<td><span className="nodes_relationship">:I18N2L {state.resource.langId}</span></td>
						<td>→</td>
						<td><span className="nodes_node">:I18N2V {state.resource.valueId}</span></td>
					</tr></tbody></table>
				</N2Clipboard>
				<Grid container spacing={1} alignItems="center">
					<Grid item className="n2grow"><FormattedDate value={array2date(state.resource.modified)} format="dateTime" /></Grid>
					<Grid item><FormControlLabel value="velocity" checked={state.language==='velocity'} control={<Radio color="default"/>} label="Velocity" onChange={changeLanguage} /></Grid>
					<Grid item><FormControlLabel value="icu-message-format" checked={state.language==='icu-message-format'} control={<Radio color="default"/>} label="ICU Message" onChange={changeLanguage} /></Grid>
					<Grid item>
						{!deeplBusy && <IconButton onClick={deepl} size="small"><Icon fontSize="small">translate</Icon></IconButton>}
						{deeplBusy && <CircularProgress size={20} />}
					</Grid>
				</Grid>
				<Editor value={state.code}
					onValueChange={v => setCode(v)}
					highlight={v => state.language ? Prism.highlight(v, Prism.languages[state.language], state.language) : v}
					insertSpaces={false}
					tabSize={1}
					padding={8}
					className="codeEditor"/>
			</form>}
			<Paper className="related">
				<Grid container direction="row">
					{otherLanguages && <Grid item xs={12} sm={6}>
						<Typography variant="h6">Other Languages</Typography>
						<Typography variant="body2">Key: {state.resource.key}</Typography>
						<List>
							{otherLanguages.map(r => <ListItem button key={r.valueId} onClick={e => showResource(r)}>
								<ListItemText primary={<>
									<LanguageBadge lang={r.lang} />
									{abbrMid(html2text(r.value), 40)}
								</>} />
							</ListItem>)}
						</List>
						<IconButton onClick={adminI18N2VCloneDialog.show}><Icon color="primary">content_copy</Icon></IconButton>
					</Grid>}
					{related && <Grid item xs={12} sm={6}>
						<Typography variant="h6">Related Resources</Typography>
						<Typography variant="body2">Matching: {related.search}</Typography>
						<List>
							{related.resources.map(r => <ListItem button key={r.valueId} onClick={e => showResource(r)}>
								<ListItemText primary={<>
									<LanguageBadge lang={r.lang} />
									<MarkMatch string={r.key} mark={related.search} />
								</>} secondary={<span className="related_valuePreview">
									{abbrMid(html2text(r.value), 60)}
								</span>} />
							</ListItem>)}
						</List>
					</Grid>}
				</Grid>
			</Paper>
		</N2Content>
		<N2AdminI18N2KCloneDialog resource={state.resource} />
		<N2AdminI18N2VCloneDialog resource={state.resource} />
		<N2AdminI18N2AddDialog defaultKey={state.resource.key} />
	</N2View> : null
}


const MarkMatch = ({ string, mark }) => {
	const marked = string.replace(mark, `<span class='related_mark'>${mark}</span>`)
	return <span dangerouslySetInnerHTML={{ __html: marked }}></span>
}


const adminI18N2KCloneDialogSubj = new Subject()
const adminI18N2KCloneDialog = {
	show: () => adminI18N2KCloneDialogSubj.next({ open: true }),
	hide: () => adminI18N2KCloneDialogSubj.next({ open: false }),
}
const N2AdminI18N2KCloneDialog = ({ resource }) => {
	const navigation = useNavigation()
	const [state, setState] = useState({ open: false })
	const { register, handleSubmit } = useForm({ defaultValues: { key: resource.key+'.clone' } })

	useEffect(() => {
		const subs = adminI18N2KCloneDialogSubj.subscribe({ next: setState })
		return () => subs.unsubscribe()
	}, [])

	const onSubmit = useCallback(data => {
		axios.post(`${APP_API_URL}/i18n2/I18N2K/${resource.keyId}/clone`, data).then(r => {
			showSnackbar(`cloned to ${r.data[0].key}`)
			navigation.navigate(`/admin/i18n2?filter.lang=&filter.search=${r.data[0].key}`)
		})
	}, [resource, navigation])

	return <Dialog open={state.open} onClose={adminI18N2KCloneDialog.hide}>
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<DialogTitle>Clone</DialogTitle>
			<DialogContent>
				<Typography component="p" gutterBottom>{resource.key} with all languages to</Typography>
				<TextField name="key" inputRef={register()} label="New Key" fullWidth />
			</DialogContent>
			<DialogActions>
				<Button onClick={adminI18N2KCloneDialog.hide}>Cancel</Button>
				<Button type="submit" color="primary">Clone</Button>
			</DialogActions>
		</form>
	</Dialog>
}


const adminI18N2VCloneDialogSubj = new Subject()
const adminI18N2VCloneDialog = {
	show: () => adminI18N2VCloneDialogSubj.next({ open: true }),
	hide: () => adminI18N2VCloneDialogSubj.next({ open: false }),
}
const N2AdminI18N2VCloneDialog = ({ resource }) => {
	const navigation = useNavigation()
	const [state, setState] = useState({ open: false })
	const { register, handleSubmit } = useForm({ defaultValues: { key: resource.key+'.clone' } })

	useEffect(() => {
		const subs = adminI18N2VCloneDialogSubj.subscribe({ next: setState })
		return () => subs.unsubscribe()
	}, [])

	const onSubmit = useCallback(data => {
		axios.post(`${APP_API_URL}/i18n2/I18N2V/${resource.valueId}/clone`, data).then(r => {
			showSnackbar(`cloned to ${r.data[0].valueId}`)
			navigation.navigate(`/admin/i18n2/${r.data[0].valueId}`)
		})
	}, [resource, navigation])

	return <Dialog open={state.open} onClose={adminI18N2VCloneDialog.hide}>
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
			<DialogTitle>Clone</DialogTitle>
			<DialogContent>
				<Typography component="p" gutterBottom>:I18N2V {resource.valueId} {resource.lang} to</Typography>
				<TextField name="lang" inputRef={register()} label="Language" fullWidth />
			</DialogContent>
			<DialogActions>
				<Button onClick={adminI18N2VCloneDialog.hide}>Cancel</Button>
				<Button type="submit" color="primary">Clone</Button>
			</DialogActions>
		</form>
	</Dialog>
}
