import { useEffect } from 'react';
import { Subject } from 'rxjs';
import { APP_API_URL } from '../app/const';
import { devlog } from './n2functions';


export const n2broadcastFeed = new Subject()


export const N2BroadcastFeed = () => {
	// const errors = useRef(0)

	useEffect(() => {
		let sse
		const initEventSource = () => {
			sse = new EventSource(`${APP_API_URL}/sse/broadcast`, { withCredentials: true });
			// sse.onerror = e => {
			// 	sse.close()
			// 	errors.current++
			// 	devlog('N2BroadcastFeed errors', errors.current)
			// 	if (errors.current < 10) setTimeout(initEventSource, random(2_000, 5_000) * errors.current)
			// }
			sse.onmessage = e => {
				devlog('/sse/broadcast', e);
				// errors.current = 0
				n2broadcastFeed.next(JSON.parse(e.data))
			}
		}
		initEventSource()

		return () => sse.close()
	}, [])

	return null
}
