import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { devlog } from './n2functions';
import { useN2I18N2 } from './N2I18N2';


const n2snackbar = new Subject()


export const showSnackbar = data => {
	let config = {
		anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
	}
	if (typeof data === 'function') data = data()
	if (typeof data === 'string') {
		config.message = data
		config.autoHideDuration = Math.min(7000, Math.max(2000, data.length*120))
	} else {
		config = { ...config, ...data }
	}
	n2snackbar.next({
		config,
		open: true
	})
}

export const showError = error => {
	const msg = error?.response?.data?.message || error.message || JSON.stringify(error)
	showSnackbar({
		content: <Alert severity="error" variant="filled">{msg}</Alert>,
		autoHideDuration: 7000
	})
}

export const hideSnackbar = () => {
	n2snackbar.next({
		open: false
	})
}


export const N2Snackbar = () => {
	const [state, setState] = useState({ open: false })

	useEffect(() => {
		devlog("N2Snackbar subscribing")
		const subj = n2snackbar.subscribe(msg => {
			devlog('N2Snackbar.next', msg)
			setState(msg)
		})
		return () => {
			devlog("N2Snackbar unsubscribing")
			subj.unsubscribe()
		}
	}, [])

	return <Snackbar open={state.open} onClose={hideSnackbar} {...state.config}>{state.config?.content}</Snackbar>
}


export const useN2Snackbar = () => {
	const n2i18n2 = useN2I18N2()

	return data => {
		if (typeof data === 'function') data = data()
		if (typeof data === 'object' && data.id) data = n2i18n2.formatMessage(data.id, data.def, data.values)
		showSnackbar(data)
	}
}
