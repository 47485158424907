import moment from "moment";
import { LOCALES } from "../app/const";


export const setStoredLocale = locale => localStorage.setItem('locale', locale)
export const getStoredLocale = () => {
	let l = localStorage.getItem('locale') || navigator.language || navigator.userLanguage || 'en'
	if (l) l = l.substring(0, 2)
	if (!Object.keys(LOCALES).includes(l)) l = 'en'
	return l
}

export function devlog() {
	if (process.env.NODE_ENV === 'development') console.log.apply(null, ['🟧[devlog]', ...arguments])
}


export function random(min ,max, round = true) {
	let r = Math.random() * (max - min) + min
	if (round) r = Math.round(r)
	return r
}

export function mapRange(value, inMin, inMax, outMin, outMax, clamp = true) {
	const v = (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
	if (clamp) return Math.max(Math.min(v, outMin), outMax)
	else return v
}

export function abbrMid(s, len, sep = '…') {
	if (!s) return ''
	if (s.length<=len) return s;
	let chars = len-sep.length;
	let front = Math.ceil(chars/2);
	let back = Math.floor(chars/2);
	return s.substr(0, front)+sep+s.substr(s.length-back);
}


export function anonymize(s) {
	return s.substr(0,2) + '···' + s.substr(-1)
}


export function html2text(html) {
	return html.replace(/(<([^>]+)>)/ig, '')
}


export function hash(str) {
    if (!str) return 0;
    if (typeof str === 'object') str = JSON.stringify(str);
    if (typeof str === 'number') str = ''+str;
    if (str.length === 0) return 0;
    var hash = 0, i, chr, len;
    for (i=0, len=str.length; i<len; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}


export function highlight(elementsClassName, hightlightClassname = 'n2highlight') {
	let ecns = []
	if (typeof elementsClassName === 'string') ecns.push(elementsClassName)
	else if (typeof elementsClassName === 'function') ecns.push(elementsClassName())
	else if (Array.isArray(elementsClassName)) ecns = elementsClassName
	else if (elementsClassName instanceof Set) ecns = Array.from(elementsClassName)
	ecns.forEach(ecn => {
		Array.prototype.forEach.call(document.getElementsByClassName(ecn), src => {
			const bcr = src.getBoundingClientRect()
			const rel = document.createElement('div')
			rel.style.position = 'relative'
			const abs = document.createElement('div')
			abs.className = hightlightClassname
			abs.style.width = bcr.width + 'px'
			abs.style.height = bcr.height + 'px'
			rel.append(abs)
			setTimeout(() => {
				src.parentElement.insertBefore(rel, src)
				setTimeout(() => abs.classList.add('fading'), 10)
				setTimeout(() => src.parentElement.removeChild(rel), 1500)
			}, 100)
			// src.classList.add(hightlightClassname)
			// setTimeout(() => {src.classList.remove(hightlightClassname)}, 1600)
		})
	})
}


export function inputError(errors, name) {
	if (errors[name]) {
		return {
			error: true,
			helperText: errors[name].message || errors[name].type
		}
	}
	return null
}


export function entityClasses(entity) {
	let c = ''
	if (entity?.id != null) c += 'n2id_' + entity.id
	if (entity?._mark != null) c += ' n2mark_' + entity._mark
	return c
}


export function floor2(num) {
	return Math.floor(num*100)/100
}


export function array2date(arr) {
	if (arr == null || !Array.isArray(arr) || arr.length === 0 || arr[0] < 0) return null
	let a = [...arr]
	if (a.length > 1) a[1] = a[1] - 1			// month index zero based
	if (a.length === 7) a = a.slice(0, -1)		// cut off nanos
	return moment(a).toDate()
}
